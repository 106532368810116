import { createAction, createAsyncAction } from 'typesafe-actions';

const reset = createAction('SETTINGS/RESET')<undefined>();

const getAccountSettings = createAsyncAction(
  'SETTINGS/GET/REQUEST',
  'SETTINGS/GET/SUCCESS',
  'SETTINGS/GET/FAILURE',
)<undefined, any, any>();

const setAccountSettings = createAction(
  'SETTINGS/SET_ACCOUNT_SETTINGS'
)<any>();

const updateAccountSettings = createAsyncAction(
  'SETTINGS/CREATE/REQUEST',
  'SETTINGS/CREATE/SUCCESS',
  'SETTINGS/CREATE/FAILURE',
)<any, any, any>();

const resetOperationStatus = createAction('SETTINGS/RESET_OPERATION_STATUS')<any>();

export default {
  reset,
  getAccountSettings,
  setAccountSettings,
  updateAccountSettings,
  resetOperationStatus
} as const;
