import * as MockMap from './mocks';
import { RequestType } from './apiTypes';

export const API_MAP = {
  login: {
    url: '/auth/login/',
    method: RequestType.POST,
    mock: MockMap.login,
  },
  cookie: {
    url: '/cookie/',
    method: RequestType.GET,
  },
  logout: {
    url: '/auth/logout/',
    method: RequestType.GET,
    mock: [200, {}],
  },
  refresh: {
    url: '/auth/token/refresh/',
    method: RequestType.POST,
    mock: [200, { token: '0000' }],
  },
  signup: {
    url: '/auth/signup/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  resetPassword: {
    url: '/auth/reset/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  firstInit: {
    url: '/auth/init-auth/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  validateToken: {
    url: '/auth/token/validate/',
    method: RequestType.POST,
  },
  getDashboard: {
    url: '/link/',
    method: RequestType.GET,
  },
  publishDashboard: {
    url: '/publish/',
    method: RequestType.GET,
  },
  updateDashboard: {
    url: '/dashboard/:id/title/update/',
    method: RequestType.PUT,
  },
  createMapWidget: {
    url: '/map-widget/create/',
    method: RequestType.POST,
  },
  createBarWidget: {
    url: '/bar-widget/create/',
    method: RequestType.POST,
  },
  createPieWidget: {
    url: '/pie-widget/create/',
    method: RequestType.POST,
  },
  createLineWidget: {
    url: '/line-widget/create/',
    method: RequestType.POST,
  },
  createTableWidget: {
    url: '/table-widget/create/',
    method: RequestType.POST,
  },
  getOrganizations: {
    url: '/organizations/',
    method: RequestType.GET,
    mock: [200, {  }],
  },
  getOrganizationDropdown: {
    url: '/organizations/names',
    method: RequestType.GET,
    mock: [200, {  }],
  },
  createOrganization: {
    url: '/organizations/create/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  getOrganization: {
    url: '/organizations/:id/',
    method: RequestType.GET,
    mock: [200, {  }],
  },
  updateOrganization: {
    url: '/organizations/:id/update/',
    method: RequestType.PUT,
    mock: [200, {  }],
  },
  updateOrganizationPartially: {
    url: '/organizations/:id/update/',
    method: RequestType.PATCH,
    mock: [200, {  }],
  },
  getClientsList: {
    url: '/end-users/',
    method: RequestType.GET,
  },
  getUsersList: {
    url: '/users/',
    method: RequestType.GET,
    mock: [200, {  }],
  },
  getUserNames: {
    url: '/users/names/',
    method: RequestType.GET,
    mock: [200, {  }],
  },
  createUser: {
    url: '/users/create/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  updateUser: {
    url: '/users/:id/update/',
    method: RequestType.PUT,
    mock: [200, {  }],
  },
  updateUserPartially: {
    url: '/users/:id/update/',
    method: RequestType.PATCH,
    mock: [200, {  }],
  },
  activateUser: {
    url: '/users/:id/activate/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  inactivateUser: {
    url: '/users/:id/inactivate/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  getPermissionsList: {
    url: '/permission/',
    method: RequestType.GET,
    mock: [200, {  }],
  },
  getRolesList: {
    url: '/group',
    method: RequestType.GET,
    mock: [200, {  }],
  },
  createRole: {
    url: '/group/',
    method: RequestType.POST,
    mock: [200, {  }],
  },
  updateRole: {
    url: '/group/:id/',
    method: RequestType.PUT,
    mock: [200, {  }],
  },
  deleteRole: {
    url: '/group/:id/',
    method: RequestType.DELETE,
    mock: [200, {  }],
  },
  getTypesList: {
    url: '/device-types/',
    method: RequestType.GET,
  },
  getTypesNames: {
    url: '/device-types/names/',
    method: RequestType.GET,
  },
  getTypeById: {
    url: '/device-types/:id',
    method: RequestType.GET,
  },
  copyTypeById: {
    url: '/device-types/:id/copy/',
    method: RequestType.POST,
  },
  createType: {
    url: '/device-types/create',
    method: RequestType.POST,
  },
  getUserProfile: {
    url: '/self-user/',
    method: RequestType.GET,
  },
  updateUserProfile: {
    url: '/self-user/update/',
    method: RequestType.PUT,
  },
  getUserPermissions: {
    url: '/self-user/permissions/',
    method: RequestType.GET,
  },
  updateAccountSettings: {
    url: '/organizations/:id/self-update/',
    method: RequestType.PUT,
  },
  getAccountSettings: {
    url: '/organizations/:id/self-update/',
    method: RequestType.GET,
  },
  createBulkOfDevices: {
    url: '/devices/bulk-create/',
    method: RequestType.POST,
  },
  getBulkUpdatesReports: {
    url: '/upload-reports/',
    method: RequestType.GET,
  },
  downloadBulkReport: {
    url: '/upload-reports/:id/download/',
    method: RequestType.GET,
  },
  getDevicesList: {
    url: '/devices/',
    method: RequestType.GET,
  },
  getTypesFilterData: {
    url: '/device-types/names',
    method: RequestType.GET,
  },
  getOrganizationsFilterData: {
    url: '/devices/filters/organizations',
    method: RequestType.GET,
  },
  getFirmwaresFilterData: {
    url: '/devices/firmware/names/',
    method: RequestType.GET,
  },
  getGroupsFilterData: {
    url: '/device-groups/names/',
    method: RequestType.GET,
  },
  getDeviceGroups: {
    url: '/device-groups/',
    method: RequestType.GET,
  },
  createDeviceGroup: {
    url: '/device-groups/create/',
    method: RequestType.POST,
  },
  getDeviceGroupById: {
    url: '/device-groups/:id/',
    method: RequestType.GET,
  },
  updateDeviceGroup: {
    url: '/device-groups/:id/update/',
    method: RequestType.PUT,
  },
  createDevice: {
    url: '/devices/create',
    method: RequestType.POST,
  },
  getDeviceById: {
    url: '/devices/:id',
    method: RequestType.GET,
  },
  getDeviceShadow: {
    url: '/devices/:id/shadow/',
    method: RequestType.GET,
    useMock: false,
  },
  updateDevice: {
    url: '/devices/:id/update',
    method: RequestType.PUT,
  },
  getFotaFilesList: {
    url: '/fota-files/',
    method: RequestType.GET,
  },
  createFotaFile: {
    url: '/fota-files/create/',
    method: RequestType.POST,
  },
  createNonIOTFotaFile: {
    url: '/non-cloud/fota-files/create/',
    method: RequestType.POST,
  },
  downloadFotaFile: {
    url: '/fota-files/:id/download/',
    method: RequestType.GET,
  },
  updateFotaFile: {
    url: '/fota-files/:id/update/',
    method: RequestType.PUT,
  },
  updateNonIOTFotaFile: {
    url: '/non-cloud/fota-files/:id/update/',
    method: RequestType.PUT,
  },
  getComponents: {
    url: '/ui-components/',
    method: RequestType.GET,
    useMock: false
  },
  createConfiguration: {
    url: '/configurations/create/',
    method: RequestType.POST,
    useMock: false
  },
  getConfiguration: {
    url: '/configurations/:id/',
    method: RequestType.GET,
    useMock: false
  },
  updateConfiguration: {
    url: '/configurations/:id/update/',
    method: RequestType.PUT,
    useMock: false
  },
  deleteConfiguration: {
    url: '/configurations/:id/delete/',
    method: RequestType.DELETE,
    useMock: false
  },
  addTelemetry: {
    url: 'telemetries/create/',
    method: RequestType.POST,
  },
  getTelemetriesNames: {
    url: 'telemetries/names/',
    method: RequestType.GET,
  },
  getTelemetriesNamesWidget: {
    url: 'telemetries/names/widget/',
    method: RequestType.GET,
  },
  getTelemetryTypeList: {
    url: 'telemetry-types/',
    method: RequestType.GET,
  },
  editTelemetry: {
    url: 'telemetries/:id/update',
    method: RequestType.PUT,
  },
  deleteTelemetry: {
    url: '/telemetries/:id/delete/',
    method: RequestType.DELETE,
    useMock: false
  },
  addTelemetryType: {
    url: 'telemetry-types/create/',
    method: RequestType.POST,
  },
  publishType: {
    url: 'device-types/:id/publish/',
    method: RequestType.GET,
  },
  updateType: {
    url: 'device-types/:id/update/',
    method: RequestType.PUT,
  },
  getFotaJobs: {
    url: '/fota-jobs/',
    method: RequestType.GET,
  },
  getFotaJobById: {
    url: '/fota-jobs/:id/',
    method: RequestType.GET,
  },
  createFotaJob: {
    url: '/fota-jobs/create/',
    method: RequestType.POST,
  },
  downloadFotaJob: {
    url: '/fota-jobs/:id/download/',
    method: RequestType.GET,
  },
  getFotaTemplates: {
    url: '/fota-job-templates/',
    method: RequestType.GET,
  },
  getFotaTemplateById: {
    url: '/fota-job-templates/:id/',
    method: RequestType.GET,
  },
  updateFotaTemplate: {
    url: '/fota-job-templates/:id/update/',
    method: RequestType.PUT,
  },
  getEvent: {
    url: '/event/templates/:id',
    method: RequestType.GET,
  },
  getEventsList: {
    url: '/event/templates/',
    method: RequestType.GET,
  },
  getEventsLogsList: {
    url: '/event/logs/',
    method: RequestType.GET,
  },
  createEvent: {
    url: '/event/templates/create',
    method: RequestType.POST,
  },
  updateEvent: {
    url: '/event/templates/:id/update',
    method: RequestType.PUT,
  },
  activateEvent: {
    url: '/event/templates/:id/instance/start',
    method: RequestType.POST,
  },
  deactivateEvent: {
    url: '/event/templates/:id/instance/stop',
    method: RequestType.POST,
  },
  getActiveEvents: {
    url: '/event/instances/count',
    method: RequestType.GET,
  },
  updateEventAudience: {
    url: '/event/templates/:id/audience/update',
    method: RequestType.PUT,
  },
};
