import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator, { APIS } from 'http/utils/requestGenerator';
import typesActions from './actions';
import { history } from 'router';

function* getTypesList({ payload }: ReturnType<typeof typesActions.getTypesList.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getTypesList, { api: APIS.devices }, params));
    if (isAxiosError) throw response;

    const { results, count } = data;
    yield put(typesActions.getTypesList.success({ types: results, count }));
  } catch (error: any) {
    yield put(typesActions.getTypesList.failure({ message: error.message, data: error.data }));
  }
}

function* getTypesNames({ payload }: ReturnType<typeof typesActions.getTypesNames.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getTypesNames, { api: APIS.devices }, params));
    if (isAxiosError) throw response;

    const { results, count } = data;
    yield put(typesActions.getTypesNames.success({ types: results, count }));
  } catch (error: any) {
    yield put(typesActions.getTypesNames.failure({ message: error.message, data: error.data }));
  }
}

function* copyType({ payload }: ReturnType<typeof typesActions.copyType.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.copyTypeById, { api: APIS.devices, id: payload.id }), payload.formData);
    if (isAxiosError) throw response;

    yield put(typesActions.copyType.success(data));
    history.push(`/devices/types/${data.id}`);
  } catch (error: any) {

    yield put(typesActions.copyType.failure({ message: error.message, data: error.data }));
  }
}

function* createType({ payload }: ReturnType<typeof typesActions.createType.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createType, { api: APIS.devices }), payload);
    if (isAxiosError) throw response;

    yield put(typesActions.createType.success(data));
    history.push(`/devices/types/${data.id}`);
  } catch (error: any) {

    yield put(typesActions.createType.failure({ message: error.message, data: error.data }));
  }
}

function* getTypeById({ payload }: ReturnType<typeof typesActions.getTypeById.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getTypeById, { api: APIS.devices, id: payload }));
    if (isAxiosError) throw response;

    yield put(typesActions.getTypeById.success(data));
  } catch (error: any) {
    yield put(typesActions.getTypeById.failure({ message: error.message, data: error.data }));
  }
}

function* updateType({ payload }: ReturnType<typeof typesActions.updateType.request>): SagaIterator {
  try {
    const { isAxiosError, response, data} = yield call(requestGenerator(API_MAP.updateType, { api: APIS.devices, id: payload.id }), payload.formData);
    if (isAxiosError) throw response;

    yield put(typesActions.updateType.success(data));
  } catch (error: any) {
    yield put(typesActions.updateType.failure({ message: error.message, data: error.data }));
  }
}

function* publishType({ payload }: ReturnType<typeof typesActions.publishType.request>): SagaIterator {
  try {
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.publishType, { api: APIS.devices, id: payload }));

    if (isAxiosError) throw Error(response?.data && response?.data?.length
      ? response?.data[0]
      : 'We can\'t connect, please try again');

    yield put(typesActions.publishType.success(payload));
  } catch (error: any) {
    yield put(typesActions.publishType.failure({ message: error.message, data: error.data }));
  }
}

function* createTelemetry({ payload }: ReturnType<typeof typesActions.createTelemetry.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.addTelemetry, { api: APIS.devices }), payload);
    if (isAxiosError) throw response;
    yield put(typesActions.createTelemetry.success(data));
  } catch (error: any) {
    yield put(typesActions.createTelemetry.failure({ message: error.message, data: error.data }));
  }
}

function* createTelemetryType({ payload }: ReturnType<typeof typesActions.createTelemetryType.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.addTelemetryType, { api: APIS.devices }), payload.params);
    if (isAxiosError) throw response;
    yield put(typesActions.createTelemetryType.success(data));
    yield payload.callBack(data);
  } catch (error: any) {
    yield put(typesActions.createTelemetryType.failure({ message: error.message, data: error.data }));
  }
}

function* editTelemetry({ payload }: ReturnType<typeof typesActions.editTelemetry.request>): SagaIterator {
  try {
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.editTelemetry, { api: APIS.devices, id: payload.id }), payload);
    if (isAxiosError) throw response;

    yield put(typesActions.editTelemetry.success(payload));
  } catch (error: any) {
    yield put(typesActions.editTelemetry.failure({ message: error.message, data: error.data }));
  }
}

function* deleteTelemetry({ payload }: ReturnType<typeof typesActions.deleteTelemetry.request>): SagaIterator {
  try {
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.deleteTelemetry, { api: APIS.devices, id: payload.id }));
    if (isAxiosError) throw response;

    yield put(typesActions.deleteTelemetry.success(payload));
  } catch (error: any) {
    yield put(typesActions.deleteTelemetry.failure({ message: error.message, data: error.data }));
  }
}

function* getTelemetryTypeList({ payload }: ReturnType<typeof typesActions.getTelemetryTypeList.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getTelemetryTypeList, { api: APIS.devices }, payload));
    if (isAxiosError) throw response;
    const { results } = data;
    yield put(typesActions.getTelemetryTypeList.success(results));
  } catch (error: any) {
    yield put(typesActions.getTelemetryTypeList.failure({ message: error.message, data: error.data }));
  }
}

function* getTelemetriesNames({ payload }: ReturnType<typeof typesActions.getTelemetriesNames.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getTelemetriesNames, { api: APIS.devices }, payload));
    if (isAxiosError) throw response;
    yield put(typesActions.getTelemetriesNames.success(data));
  } catch (error: any) {
    yield put(typesActions.getTelemetriesNames.failure({ message: error.message, data: error.data }));
  }
}

function* getTelemetriesNamesWidget({ payload }: ReturnType<typeof typesActions.getTelemetriesNamesWidget.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getTelemetriesNamesWidget, { api: APIS.devices }, payload));
    if (isAxiosError) throw response;

    yield put(typesActions.getTelemetriesNamesWidget.success(data));
  } catch (error: any) {
    yield put(typesActions.getTelemetriesNamesWidget.failure({ message: error.message, data: error.data }));
  }
}

function* getComponents({ payload }: ReturnType<typeof typesActions.getComponents.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getComponents, { api: APIS.devices }, payload));
    if (isAxiosError) throw response;

    yield put(typesActions.getComponents.success(data));
  } catch (error: any) {
    yield put(typesActions.getComponents.failure({ message: error.message, data: error.data }));
  }
}

function* createConfiguration({ payload }: ReturnType<typeof typesActions.createConfiguration.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createConfiguration, { api: APIS.devices }), payload);
    if (isAxiosError) throw response;

    yield put(typesActions.createConfiguration.success(data));
  } catch (error: any) {
    yield put(typesActions.createConfiguration.failure({ message: error.message, data: error.data }));
  }
}

function* getConfiguration({ payload }: ReturnType<typeof typesActions.getConfiguration.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getConfiguration, { api: APIS.devices,  }));
    if (isAxiosError) throw response;

    yield put(typesActions.getConfiguration.success(data));
  } catch (error: any) {
    yield put(typesActions.getConfiguration.failure({ message: error.message, data: error.data }));
  }
}

function* updateConfiguration({ payload }: ReturnType<typeof typesActions.updateConfiguration.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateConfiguration, { api: APIS.devices, id: payload.id }),payload);
    if (isAxiosError) throw response;

    yield put(typesActions.updateConfiguration.success(data));
  } catch (error: any) {
    yield put(typesActions.updateConfiguration.failure({ message: error.message, data: error.data }));
  }
}

function* deleteConfiguration({ payload }: ReturnType<typeof typesActions.deleteConfiguration.request>): SagaIterator {
  try {
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.deleteConfiguration, { api: APIS.devices, id: payload.id }));
    if (isAxiosError) throw response;

    yield put(typesActions.deleteConfiguration.success(payload));
  } catch (error: any) {
    yield put(typesActions.deleteConfiguration.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(typesActions.getTypesList.request, getTypesList),
    takeEvery(typesActions.getTypesNames.request, getTypesNames),
    takeEvery(typesActions.copyType.request, copyType),
    takeEvery(typesActions.createType.request, createType),
    takeEvery(typesActions.getTypeById.request, getTypeById),
    takeEvery(typesActions.updateType.request, updateType),
    takeEvery(typesActions.publishType.request, publishType),
    takeEvery(typesActions.createTelemetry.request, createTelemetry),
    takeEvery(typesActions.createTelemetryType.request, createTelemetryType),
    takeEvery(typesActions.getTelemetryTypeList.request, getTelemetryTypeList),
    takeEvery(typesActions.editTelemetry.request, editTelemetry),
    takeEvery(typesActions.deleteTelemetry.request, deleteTelemetry),
    takeEvery(typesActions.getTelemetriesNames.request, getTelemetriesNames),
    takeEvery(typesActions.getTelemetriesNamesWidget.request, getTelemetriesNamesWidget),
    takeEvery(typesActions.getComponents.request, getComponents),
    takeEvery(typesActions.createConfiguration.request, createConfiguration),
    takeEvery(typesActions.getConfiguration.request, getConfiguration),
    takeEvery(typesActions.updateConfiguration.request, updateConfiguration),
    takeEvery(typesActions.deleteConfiguration.request, deleteConfiguration),
  ]);
}
