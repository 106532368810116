import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('AUTH/RESET')<undefined>();

const setLoggedIn = createAsyncAction(
  'AUTH/LOGIN/REQUEST',
  'AUTH/LOGIN/SUCCESS',
  'AUTH/LOGIN/FAILURE',
)<any, any, any>();

const setLoggedOut = createAsyncAction(
  'AUTH/LOGOUT/REQUEST',
  'AUTH/LOGOUT/SUCCESS',
  'AUTH/LOGOUT/FAILURE',
)<undefined, undefined, any>();

const setSignedUp = createAsyncAction(
  'AUTH/SIGN_UP/REQUEST',
  'AUTH/SIGN_UP/SUCCESS',
  'AUTH/SIGN_Up/FAILURE',
)<any, undefined, any>();

const resetPassword = createAsyncAction(
  'AUTH/RESET_PASSWORD/REQUEST',
  'AUTH/RESET_PASSWORD/SUCCESS',
  'AUTH/RESET_PASSWORD/FAILURE',
)<any, undefined, any>();

const initializeUser = createAsyncAction(
  'AUTH/FIRST_INIT/REQUEST',
  'AUTH/FIRST_INIT/SUCCESS',
  'AUTH/FIRST_INIT/FAILURE',
)<any, undefined, any>();

const validateToken = createAsyncAction(
  'AUTH/VALIDATE_TOKEN/REQUEST',
  'AUTH/VALIDATE_TOKEN/SUCCESS',
  'AUTH/VALIDATE_TOKEN/FAILURE',
)<any, any, any>();

const setEmail = createAction('AUTH/SET_EMAIL')<string>();

const setAuthenticating = createAction('LOGOUT/SET_AUTHENTICATING')<boolean>();

export default {
  reset,
  setLoggedIn,
  setLoggedOut,
  setSignedUp,
  resetPassword,
  initializeUser,
  validateToken,
  setAuthenticating,
  setEmail,
} as const;
