import { createAction, createAsyncAction } from 'typesafe-actions';

const getUserProfile = createAsyncAction(
  'USER_PROFILE/GET/REQUEST',
  'USER_PROFILE/GET/SUCCESS',
  'USER_PROFILE/GET/FAILURE',
)<undefined, any, any>();

const updateUserProfile = createAsyncAction(
  'USER_PROFILE/CREATE/REQUEST',
  'USER_PROFILE/CREATE/SUCCESS',
  'USER_PROFILE/CREATE/FAILURE',
)<any, any, any>();

const getUserPermissions = createAsyncAction(
  'USER_PERMISSIONS/GET/REQUEST',
  'USER_PERMISSIONS/GET/SUCCESS',
  'USER_PERMISSIONS/GET/FAILURE',
)<undefined, any, any>();

const reset = createAction('USER_PROFILE/RESET')<undefined>();

const resetOperationStatus = createAction('USER_PROFILE/RESET_OPERATION_STATUS')<any>();
export default {
  reset,
  getUserProfile,
  updateUserProfile,
  getUserPermissions,
  resetOperationStatus
} as const;
