const envs = [
  process.env.REACT_APP_API_ENDPOINT,
  process.env.REACT_APP_DEVICES_API_ENDPOINT,
  process.env.REACT_APP_APOLLO_LINK,
];

if (envs.some(env => !env)) {
  throw new Error('Malformed config');
}

const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  devicesApiEndpoint: process.env.REACT_APP_DEVICES_API_ENDPOINT,
  appSync: process.env.REACT_APP_APOLLO_LINK,
};

export default config;
