import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from '@apollo/client';
import apiConfig from 'http/apiConfig';
import store from '../store';

const httpLink = createHttpLink({
  uri: apiConfig.appSync,
});

const authLink = setContext((_, { headers }) => {
  const {
    auth: { AccessToken },
  } = store.getState();
  return {
    headers: {
      ...headers,
      authorization: AccessToken,
    },
  };
});

export default { httpLink, authLink };
