import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator, { APIS } from 'http/utils/requestGenerator';
import devicesActions from './actions';

function* getDevicesList({ payload }: ReturnType<typeof devicesActions.getDevicesList.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getDevicesList, { api: APIS.devices }, params));

    if (isAxiosError) throw response;

    const { results, count } = data;
    yield put(devicesActions.getDevicesList.success({ devices: results, count }));
  } catch (error: any) {
    yield put(devicesActions.getDevicesList.failure({ message: error.message, data: error.data }));
  }
}

function* getTypesFilterList({ payload }: ReturnType<typeof devicesActions.getTypesFilterData.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getTypesFilterData, { api: APIS.devices }));
    if (isAxiosError) throw response;

    const { results } = data;
    yield put(devicesActions.getTypesFilterData.success(results));
  } catch (error: any) {
    yield put(devicesActions.getTypesFilterData.failure({ message: error.message, data: error.data }));
  }
}

function* getOrganizationsFilterList({ payload }: ReturnType<typeof devicesActions.getOrganizationsFilterData.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getOrganizationDropdown, { api: APIS.default }));
    if (isAxiosError) throw response;

    const { results } = data;
    yield put(devicesActions.getOrganizationsFilterData.success(results));
  } catch (error: any) {
    yield put(devicesActions.getOrganizationsFilterData.failure({ message: error.message, data: error.data }));
  }
}

function* getFirmwaresFilterList({ payload }: ReturnType<typeof devicesActions.getFirmwaresFilterData.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getFirmwaresFilterData, { api: APIS.devices }));
    if (isAxiosError) throw response;

    const { results } = data;
    yield put(devicesActions.getFirmwaresFilterData.success(results));
  } catch (error: any) {
    yield put(devicesActions.getFirmwaresFilterData.failure({ message: error.message, data: error.data }));
  }
}

function* getGroupsFilterList({ payload }: ReturnType<typeof devicesActions.getGroupsFilterData.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getGroupsFilterData, { api: APIS.devices }));
    if (isAxiosError) throw response;

    const { results } = data;
    yield put(devicesActions.getGroupsFilterData.success(results));
  } catch (error: any) {
    yield put(devicesActions.getGroupsFilterData.failure({ message: error.message, data: error.data }));
  }
}

function* createDevice({ payload }: ReturnType<typeof devicesActions.createDevice.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createDevice, { api: APIS.devices }), payload);
    if (isAxiosError) throw response;

    yield put(devicesActions.createDevice.success(data));
  } catch (error: any) {
    yield put(devicesActions.createDevice.failure({ message: error.message, data: error.data }));
  }
}

function* updateDevice({ payload }: ReturnType<typeof devicesActions.updateDevice.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateDevice, { api: APIS.devices, id: payload.id }), payload);
    if (isAxiosError) throw response;
    yield put(devicesActions.updateDevice.success(data));
  } catch (error: any) {
    yield put(devicesActions.updateDevice.failure({ message: error.message, data: error.data }));
  }
}

function* getCurrentDevice({ payload }: ReturnType<typeof devicesActions.getCurrentDevice.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getDeviceById, { api: APIS.devices, id: payload.deviceId }));
    if (isAxiosError) throw response;

    yield put(devicesActions.getCurrentDevice.success(data));
  } catch (error: any) {
    yield put(devicesActions.getCurrentDevice.failure({ message: error.message, data: error.data }));
  }
}

function* getDeviceShadow({ payload }: ReturnType<typeof devicesActions.getDeviceShadow.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getDeviceShadow, { api: APIS.devices, id: payload.deviceId }));
    if (isAxiosError) throw response;

    payload.callBack(data);
    yield put(devicesActions.getDeviceShadow.success(data));
  } catch (error: any) {
    yield put(devicesActions.getDeviceShadow.failure({ message: error.message, data: error.data }));
  }
}

function* createBulkDevice({ payload }: ReturnType<typeof devicesActions.createBulkDevice.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createBulkOfDevices, { api: APIS.devices }), payload);
    if (isAxiosError) throw response;

    yield put(devicesActions.createBulkDevice.success(data));
  } catch (error: any) {
    yield put(devicesActions.createBulkDevice.failure({ message: error.message, data: error.data }));
  }
}

function* getBulkReportsList({ payload }: ReturnType<typeof devicesActions.getBulkReports.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getBulkUpdatesReports, { api: APIS.devices }, params));
    if (isAxiosError) throw response;

    const { results, count } = data;
    yield put(devicesActions.getBulkReports.success({ bulkReports: results, count }));
  } catch (error: any) {
    yield put(devicesActions.getBulkReports.failure({ message: error.message, data: error.data }));
  }
}

function* downloadBulkReport({ payload }: ReturnType<typeof devicesActions.downloadBulkReport.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.downloadBulkReport, { api: APIS.devices, id: payload }));
    if (isAxiosError) throw response;
    yield put(devicesActions.downloadBulkReport.success(data));
    window.open(data);
  } catch (error: any) {
    yield put(devicesActions.downloadBulkReport.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(devicesActions.getDevicesList.request, getDevicesList),
    takeEvery(devicesActions.getTypesFilterData.request, getTypesFilterList),
    takeEvery(devicesActions.getOrganizationsFilterData.request, getOrganizationsFilterList),
    takeEvery(devicesActions.getFirmwaresFilterData.request, getFirmwaresFilterList),
    takeEvery(devicesActions.getGroupsFilterData.request, getGroupsFilterList),
    takeEvery(devicesActions.createDevice.request, createDevice),
    takeEvery(devicesActions.updateDevice.request, updateDevice),
    takeEvery(devicesActions.getCurrentDevice.request, getCurrentDevice),
    takeEvery(devicesActions.getDeviceShadow.request, getDeviceShadow),
    takeEvery(devicesActions.createBulkDevice.request, createBulkDevice),
    takeEvery(devicesActions.getBulkReports.request, getBulkReportsList),
    takeEvery(devicesActions.downloadBulkReport.request, downloadBulkReport),
  ]);
}
