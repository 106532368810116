import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator from 'http/utils/requestGenerator';
import userProfileActions from './actions';

function* getUserProfile(): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getUserProfile));
    if (isAxiosError) throw response;
    yield put(userProfileActions.getUserProfile.success(data));
  } catch (error: any) {
    yield put(userProfileActions.getUserProfile.failure({ message: error.message, data: error.data }));
  }
}

function* updateUserProfile({ payload }: ReturnType<typeof userProfileActions.updateUserProfile.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateUserProfile), payload);
    if (isAxiosError) throw response;

    yield put(userProfileActions.updateUserProfile.success(data));
  } catch (error: any) {
    yield put(userProfileActions.updateUserProfile.failure({ message: error.message, data: error.data }));
  }
}

function* getUserPermissions(): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getUserPermissions));
    if (isAxiosError) throw response;
    window.localStorage.setItem('permissions', JSON.stringify(data));
    yield put(userProfileActions.getUserPermissions.success(data));
  } catch (error: any) {
    yield put(userProfileActions.getUserPermissions.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(userProfileActions.getUserProfile.request, getUserProfile),
    takeEvery(userProfileActions.updateUserProfile.request, updateUserProfile),
    takeEvery(userProfileActions.getUserPermissions.request, getUserPermissions),
  ]);
}
