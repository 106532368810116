import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';
import { OperationStatus, UsersReducer } from '../types.d';

type UsersActions = ActionType<typeof actions>;

const initialState: UsersReducer = {
  users: [],
  userNames: [],
  numUsers: 0,
  getUsersListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getUserNamesStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  createUserStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateUserStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  activateUserStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  inactivateUserStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
};

const reducer = createReducer<UsersReducer, UsersActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    },
  }))
  .handleAction(actions.resetUsersList, (state) => ({
    ...state,
    users: []
  }))
  .handleAction(actions.getUsersList.request, (state) => ({
    ...state,
    getUsersListStatus: {
      ...state.getUsersListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getUsersList.success, (state, { payload: { users, numUsers } }) => ({
    ...state,
    users: [
      ...state.users,
      ...users
    ],
    numUsers,
    getUsersListStatus: {
      ...state.getUsersListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getUsersList.failure, (state, { payload: error }) => ({
    ...state,
    getUsersListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getUserNames.request, (state) => ({
    ...state,
    getUserNamesStatus: {
      ...state.getUserNamesStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getUserNames.success, (state, { payload: { userNames } }) => ({
    ...state,
    userNames,
    getUserNamesStatus: {
      ...state.getUserNamesStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getUserNames.failure, (state, { payload: error }) => ({
    ...state,
    getUserNamesStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createUser.request, (state) => ({
    ...state,
    createUserStatus: {
      ...state.createUserStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.createUser.success, (state, { payload }) => ({
    ...state,
    users: [
      ...state.users,
      payload
    ],
    createUserStatus: {
      ...state.createUserStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.createUser.failure, (state, { payload: error }) => ({
    ...state,
    createUserStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateUser.request, (state) => ({
    ...state,
    updateUserStatus: {
      ...state.updateUserStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.updateUser.success, (state, { payload: user }) => {
    const users = state.users.map(item => {
      if (item.id !== user.id) {
        return item
      }
      return user;
    });
    return {
      ...state,
      users,
      updateUserStatus: {
        ...state.updateUserStatus,
        status: OperationStatus.SUCCESS
      },
    };
  })
  .handleAction(actions.updateUser.failure, (state, { payload: error }) => ({
    ...state,
    updateUserStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }));

export default reducer;
