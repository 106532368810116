import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { history } from 'router';
import { API_MAP } from 'http/apiMap';
import requestGenerator from 'http/utils/requestGenerator';
import authActions from './actions';
import appActions from '../app/actions';
import { clearAccessTokenCookie, setAccessTokenCookie } from 'http/utils/tokenCookies';

function* loginUser({ payload }: ReturnType<typeof authActions.setLoggedIn.request>): SagaIterator {
  try {
    const { email, password } = payload;

    if (window.localStorage.getItem('credentials')) {
      const parsedCredentials = JSON.parse(window.localStorage.getItem('credentials') as string);
      if (parsedCredentials?.AccessToken) {
        yield put(authActions.setLoggedIn.success({ ...parsedCredentials }));
      }
      yield put(authActions.setAuthenticating(false));
      history.push('/');
      return;
    }

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.login), { username: email, password });

    if (isAxiosError) {
      if (response?.message === 'First login') {
        yield put(authActions.setEmail(email));
        window.localStorage.setItem('email', email);
        history.push('/auth/resetpassword');
        return;
      } else {
        throw response;
      }
    }

    yield put(authActions.setLoggedIn.success(data));
    setAccessTokenCookie(data)
    window.localStorage.setItem('credentials', JSON.stringify(data));
    history.push('/');
    yield put(authActions.setAuthenticating(false));
  } catch (error: any) {
    yield put(authActions.setLoggedIn.failure({ message: error.message, data: error.data }));
  }
}

function* signUpUser({ payload }: ReturnType<typeof authActions.setSignedUp.request>): SagaIterator {
  try {
    const { first_name, last_name, email, phone_number, token, clb } = payload;
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.signup), { first_name, last_name, email, phone_number, token });

    if (isAxiosError) throw response;

    yield put(authActions.setSignedUp.success());

    if (clb) clb();
  } catch (error: any) {
    yield put(authActions.setSignedUp.failure({ message: error.message, data: error.data }));
  }
}

function* resetPassword({ payload }: ReturnType<typeof authActions.resetPassword.request>): SagaIterator {
  try {
    const { email, clb } = payload;
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.resetPassword), { email });

    if (isAxiosError) throw response;

    window.localStorage.setItem('email', email);
    yield put(authActions.resetPassword.success());
    if (clb) clb();
  } catch (error: any) {
    yield put(authActions.resetPassword.failure({ message: error.message, data: error.data }));
  }
}

function* initializeUser({ payload }: ReturnType<typeof authActions.initializeUser.request>): SagaIterator {
  try {
    const { username, password, new_password, clb } = payload;
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.firstInit), { username, password, new_password });

    if (isAxiosError) throw response;

    yield put(authActions.initializeUser.success());

    window.localStorage.removeItem('email');
    if (clb) clb();
  } catch (error: any) {
    yield put(authActions.initializeUser.failure({ message: error.message, data: error.data }));
  }
}

function* validateToken({ payload }: ReturnType<typeof authActions.validateToken.request>): SagaIterator {
  try {
    const { token } = payload;
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.firstInit), { token });

    if (isAxiosError) throw response;

    const { email } = data;
    yield put(authActions.validateToken.success({ email }));
  } catch (error: any) {
    yield put(authActions.validateToken.failure({ message: error.message, data: error.data }));
  }
}

function* logoutUser(): SagaIterator {
  try {
    yield call(requestGenerator(API_MAP.logout));
    yield put(authActions.setLoggedOut.success());
    yield put(appActions.reset());
    clearAccessTokenCookie()
    window.localStorage.removeItem('credentials');
    window.localStorage.removeItem('permissions');
  } catch (error: any) {
    yield put(authActions.setLoggedOut.failure({ error: 'Smth went wrong' }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(authActions.setLoggedIn.request, loginUser),
    takeEvery(authActions.resetPassword.request, resetPassword),
    takeEvery(authActions.setSignedUp.request, signUpUser),
    takeEvery(authActions.initializeUser.request, initializeUser),
    takeEvery(authActions.validateToken.request, validateToken),
    takeEvery(authActions.setLoggedOut.request, logoutUser),
  ]);
}
