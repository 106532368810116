import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import dashboardActions from '../dashboard/actions';
import requestGenerator, { APIS } from '../../http/utils/requestGenerator';
import { API_MAP } from '../../http/apiMap';

function* getDashboard(): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getDashboard, { api: APIS.dashboard }));

    if (isAxiosError) throw response;

    yield put(dashboardActions.getDashboard.success(data));
  } catch (error: any) {
    yield put(dashboardActions.getDashboard.failure({ message: error.message, data: error.data }));
  }
}

function* publishDashboard(): SagaIterator {
  try {
    const { isAxiosError, response } = yield call(requestGenerator(API_MAP.publishDashboard, { api: APIS.dashboard }));

    if (isAxiosError) throw response;

    yield put(dashboardActions.publishDashboard.success());
  } catch (error: any) {
    yield put(dashboardActions.publishDashboard.failure({ message: error.message, data: error.data }));
  }
}

function* updatehDashboard({ payload }: ReturnType<typeof dashboardActions.updateDashboard.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateDashboard, { api: APIS.dashboard, id: payload.id }), payload);

    if (isAxiosError) throw response;

    yield put(dashboardActions.updateDashboard.success(data));
  } catch (error: any) {
    yield put(dashboardActions.updateDashboard.failure({ error: { message: error.message, data: error.data }, title: payload.oldTitle }));
  }
}

function* createMapWidget({ payload }: ReturnType<typeof dashboardActions.createMapWidget.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createMapWidget, { api: APIS.dashboard }), payload);

    if (isAxiosError) throw response;

    yield put(dashboardActions.createMapWidget.success(data));
  } catch (error: any) {
    yield put(dashboardActions.createMapWidget.failure({ message: error.message, data: error.data }));
  }
}

function* createBarWidget({ payload }: ReturnType<typeof dashboardActions.createBarWidget.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createBarWidget, { api: APIS.dashboard }), payload);

    if (isAxiosError) throw response;

    yield put(dashboardActions.createBarWidget.success(data));
  } catch (error: any) {
    yield put(dashboardActions.createBarWidget.failure({ message: error.message, data: error.data }));
  }
}

function* createPieWidget({ payload }: ReturnType<typeof dashboardActions.createPieWidget.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createPieWidget, { api: APIS.dashboard }), payload);

    if (isAxiosError) throw response;

    yield put(dashboardActions.createPieWidget.success(data));
  } catch (error: any) {
    yield put(dashboardActions.createPieWidget.failure({ message: error.message, data: error.data }));
  }
}

function* createLineWidget({ payload }: ReturnType<typeof dashboardActions.createLineWidget.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createLineWidget, { api: APIS.dashboard }), payload);

    if (isAxiosError) throw response;

    yield put(dashboardActions.createLineWidget.success(data));
  } catch (error: any) {
    yield put(dashboardActions.createLineWidget.failure({ message: error.message, data: error.data }));
  }
}

function* createTableWidget({ payload }: ReturnType<typeof dashboardActions.createTableWidget.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createTableWidget, { api: APIS.dashboard }), payload);

    if (isAxiosError) throw response;

    yield put(dashboardActions.createTableWidget.success(data));
  } catch (error: any) {
    yield put(dashboardActions.createTableWidget.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(dashboardActions.getDashboard.request, getDashboard),
    takeEvery(dashboardActions.publishDashboard.request, publishDashboard),
    takeEvery(dashboardActions.updateDashboard.request, updatehDashboard),
    takeEvery(dashboardActions.createMapWidget.request, createMapWidget),
    takeEvery(dashboardActions.createBarWidget.request, createBarWidget),
    takeEvery(dashboardActions.createPieWidget.request, createPieWidget),
    takeEvery(dashboardActions.createLineWidget.request, createLineWidget),
    takeEvery(dashboardActions.createTableWidget.request, createTableWidget),
  ]);
}
