import type { AxiosRequestConfig } from 'axios';

export type CallbackResponseSpecFunc = (config: AxiosRequestConfig) => any[] | Promise<any[]>;

export const login: CallbackResponseSpecFunc = requestConfig => {
  const { data } = requestConfig;
  if (data.email === 'user@example.com' && data.password === '123') {
    return [
      200,
      {
        id: '1',
        email: data.emai,
        firstName: 'first',
        lastName: 'last',
        token: '0000',
      },
    ];
  }
  return [
    401,
    {
      error: 'Wrong email or password',
    },
  ];
};
