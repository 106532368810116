import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';
import { AppReducer } from '../types.d';

type AppActions = ActionType<typeof actions>;

const initialState: AppReducer = {
  isLeftSideMenuOpen: true,
};

const reducer = createReducer<AppReducer, AppActions>(initialState)
  .handleAction(actions.toggleMenu, (state) => ({
    ...state,
    isLeftSideMenuOpen: !state.isLeftSideMenuOpen,
  }));

export default reducer;
