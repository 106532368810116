import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies('accessToken');

const cookieOptions: CookieSetOptions = { domain: 'iot.comm-it.com' };

export const setAccessTokenCookie = (credentials: any): void => {
  const token = (credentials || JSON.parse(window.localStorage.getItem('credentials') as string))?.AccessToken;
  if (token) cookies.set('accessToken', token, cookieOptions);
}

export const clearAccessTokenCookie = (): void => {
  cookies.remove('accessToken');
}
