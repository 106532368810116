// import { LogOut } from './../types.d';
import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';
import { AuthReducer, OperationStatus } from '../types.d';

type AuthActions = ActionType<typeof actions>;

const initialState: AuthReducer = {
  id: '',
  AccessToken: '',
  IPI: '',
  IdToken: '',
  RefreshToken: '',
  UPI: '',
  email: '',
  authenticationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  loginStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  resetPasswordStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  signUpStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  firstLoginStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  tokenValidationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  firstName: '',
  lastName: '',
  isAuthenticating: true,
  isAuthenticated: false,
};

const reducer = createReducer<AuthReducer, AuthActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.setAuthenticating, (state, { payload: isAuthenticating }) => ({
    ...state,
    isAuthenticating,
  }))
  .handleAction(actions.setEmail, (state, { payload: email }) => ({
    ...state,
    email
  }))
  .handleAction(actions.setLoggedIn.success, (state, { payload }) => ({
    ...state,
    ...payload,
    isAuthenticated: true,
    loginStatus: {
      ...state.loginStatus,
      status: OperationStatus.SUCCESS
    }
  }))
  .handleAction(actions.setLoggedIn.failure, (state, { payload: error }) => ({
    ...state,
    loginStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.setSignedUp.success, (state) => ({
    ...state,
    signUpStatus: {
      ...state.signUpStatus,
      status: OperationStatus.SUCCESS
    }
  }))
  .handleAction(actions.setSignedUp.failure, (state, { payload: error }) => ({
    ...state,
    signUpStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.resetPassword.success, (state) => ({
    ...state,
    resetPasswordStatus: {
      ...state.resetPasswordStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.resetPassword.failure, (state, { payload: error }) => ({
    ...state,
    resetPasswordStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.initializeUser.success, (state) => ({
    ...state,
    firstLoginStatus: {
      ...state.firstLoginStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.initializeUser.failure, (state, { payload: error }) => ({
    ...state,
    firstLoginStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.validateToken.success, (state, { payload: { email } }) => ({
    ...state,
    email,
    tokenValidationStatus: {
      ...state.tokenValidationStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.validateToken.failure, (state, { payload: error }) => ({
    ...state,
    tokenValidationStatus: {
      errorMessage: error.message,
      errorData: error.data,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.setLoggedOut.request, state => ({
    ...state,
    isLoggingOut: true,
  }))
  .handleAction(actions.setLoggedOut.success, state => ({
    ...state,
    isLoggedIn: false,
    isAuthenticated: false,
    isAuthenticating: false,
    isLoggingOut: false,
    token: '',
    refreshToken: '',
  }))
  .handleAction(actions.setLoggedOut.failure, state => ({
    ...state,
    isLoggingOut: false,
  }));

export default reducer;
