import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router-dom';
import authActions from 'store/auth/actions';
import store from 'store/index';
import { API_MAP } from 'http/apiMap';
import { http } from '../index';
import { setAccessTokenCookie } from './tokenCookies';

let isAlreadyFetchingToken = false;
let requestsArray: any[] = [];
let timer: NodeJS.Timer;

const addSubscriber = (callback: any) => {
  requestsArray.push(callback);
};

const onTokenFetched = (newHeaders: any) => {
  requestsArray.forEach(callback => callback(newHeaders));
  requestsArray = [];
};

const refreshTokenAction = async (axiosInstance: AxiosInstance, error: any): Promise<any> => {
  try {
    const parsedCredentials = JSON.parse(window.localStorage.getItem('credentials') as string);

    const response = await axiosInstance({
      method: API_MAP.refresh.method,
      url: generatePath(API_MAP.refresh.url),
      headers: {
        idtoken: parsedCredentials.IdToken,
        refreshtoken: parsedCredentials.RefreshToken
      }
    });

    if (!response.data) {
      return Promise.reject(error);
    }

    setAccessTokenCookie({ ...parsedCredentials, ...response.data })
    window.localStorage.setItem('credentials', JSON.stringify({ ...parsedCredentials, ...response.data }));

    return Promise.resolve(response);
  } catch (e) {
    return Promise.reject(error);
  }
}

export const refreshTokenUtil = async (axiosInstance: AxiosInstance, error: any): Promise<unknown> => {
  try {
    const retryFailedRequest = new Promise(resolve => {
      addSubscriber((newHeaders: any) => {
        error.response.config.headers = newHeaders;
        resolve(axiosInstance(error.response.config));
      });
    });

    if (!isAlreadyFetchingToken) {
      isAlreadyFetchingToken = true;

      const response = await refreshTokenAction(axiosInstance, error);

      isAlreadyFetchingToken = false;

      onTokenFetched({
        authorization: response.data.AccessToken,
        idtoken: response.data.IdToken,
        ipi: response.data.IPI,
        upi: response.data.UPI,
      });
    }

    return retryFailedRequest;
  } catch (exception) {
    clearTimeout(timer);
    isAlreadyFetchingToken = false;
    return Promise.reject(exception);
  }
};

interface IJwtData {
  client_id: string;
  exp: number;
  iat: number;
  username: string;
}

const decodeCredentialsToken = (token: string): IJwtData | null => {
  try {
    const jwt = JSON.parse(window.localStorage.getItem('credentials') as string)[token];
    if (!jwt) return null;
    const data = atob(jwt.split('.')[1]);
    return JSON.parse(data)
  } catch (e) {
    return null;
  }
}

// in milliseconds
const getTokenLifetime = (token: string): number | null => {
  const jwt = decodeCredentialsToken(token);
  if (!jwt) return null;
  const expiration = new Date(jwt.exp * 1000)
  const now = new Date();
  const diff = expiration.getTime() - now.getTime();
  return diff > 0 ? diff : null;
};

export const dashboardCounter = (): any => {
  const timeout = getTokenLifetime('AccessToken');
  if (!timeout) return;
  timer = setTimeout(() => {
    refreshTokenAction(http, 'Token rejected')
      .then(() => {
        dashboardCounter();
      })
      .catch(() => {
        store.dispatch(authActions.setLoggedOut.request());
      })
  }, timeout);
  return () => {
    clearTimeout(timer);
  };
}
