import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator from 'http/utils/requestGenerator';
import usersActions from './actions';

function* getUsersList({ payload } : ReturnType<typeof usersActions.getUsersList.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getUsersList, undefined, params));
    if (isAxiosError) throw response;

    const { results, count } = data;

    yield put(usersActions.getUsersList.success({ users: results, numUsers: count }));
  } catch (error: any) {
    yield put(usersActions.getUsersList.failure({ message: error.message, data: error.data }));
  }
}

function* getUserNames({ payload } : ReturnType<typeof usersActions.getUserNames.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getUserNames, undefined, payload));
    if (isAxiosError) throw response;

    yield put(usersActions.getUserNames.success({ userNames: data }));
  } catch (error: any) {
    yield put(usersActions.getUserNames.failure({ message: error.message }));
  }
}

function* createUser({ payload }: ReturnType<typeof usersActions.createUser.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createUser), payload);
    if (isAxiosError) throw response;

    yield put(usersActions.createUser.success(data));
  } catch (error: any) {
    yield put(usersActions.createUser.failure({ message: error.message, data: error.data }));
  }
}

function* updateUser({ payload }: ReturnType<typeof usersActions.updateUser.request>): SagaIterator {
  try {
    const id = payload.id;
    delete payload.id

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateUser, { id }), payload);
    if (isAxiosError) throw response;

    yield put(usersActions.updateUser.success(data));
  } catch (error: any) {
    yield put(usersActions.updateUser.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(usersActions.getUsersList.request, getUsersList),
    takeEvery(usersActions.getUserNames.request, getUserNames),
    takeEvery(usersActions.createUser.request, createUser),
    takeEvery(usersActions.updateUser.request, updateUser),
  ]);
}
