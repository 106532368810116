import { all, fork } from 'redux-saga/effects';
import { appSaga } from './app';
import { authSaga } from './auth';
import { organizationsSaga } from './organizations';
import { usersSaga } from './users';
import { clientsSaga } from './clients';
import { typesSaga } from './deviceTypes/index';
import { rolesSaga } from './roles';
import { permissionsSaga } from './permisssions';
import { accountSettingsSaga } from './settings';
import { userProfileSaga } from './userProfile';
import { devicesSaga } from './devices';
import { deviceGroupSaga } from './devicegroups';
import { fotaSaga } from './fota';
import { dashboardSaga } from './dashboard';
import { eventsSaga } from './events';

export default function* rootSaga(): any {
  yield all([
    fork(appSaga),
    fork(authSaga),
    fork(dashboardSaga),
    fork(organizationsSaga),
    fork(usersSaga),
    fork(clientsSaga),
    fork(typesSaga),
    fork(rolesSaga),
    fork(permissionsSaga),
    fork(accountSettingsSaga),
    fork(userProfileSaga),
    fork(devicesSaga),
    fork(deviceGroupSaga),
    fork(fotaSaga),
    fork(eventsSaga),
  ]);
}
