import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator, { APIS } from 'http/utils/requestGenerator';
import eventsActions from './actions';

function* getEventsList({ payload }: ReturnType<typeof eventsActions.getEventsList.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getEventsList, { api: APIS.events }, params));

    if (isAxiosError) throw response;

    const { results, count } = data;
    yield put(eventsActions.getEventsList.success({ events: results, count }));
  } catch (error: any) {
    yield put(eventsActions.getEventsList.failure({ message: error.message, data: error.data }));
  }
}

function* getEventsLogsList({ payload }: ReturnType<typeof eventsActions.getEventsLogsList.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getEventsLogsList, { api: APIS.events }, params));

    if (isAxiosError) throw response;

    const { results, count } = data;
    yield put(eventsActions.getEventsLogsList.success({ eventsLogs: results, count }));
  } catch (error: any) {
    yield put(eventsActions.getEventsLogsList.failure({ message: error.message, data: error.data }));
  }
}

function* createEvent({ payload }: ReturnType<typeof eventsActions.createEvent.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createEvent, { api: APIS.events }), payload);
    if (isAxiosError) throw response;

    yield put(eventsActions.createEvent.success(data));
  } catch (error: any) {
    yield put(eventsActions.createEvent.failure({ message: error.message, data: error.data }));
  }
}

function* updateEvent({ payload }: ReturnType<typeof eventsActions.updateEvent.request>): SagaIterator {
  try {
    const id = payload.id;
    delete payload.id

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateEvent, { api: APIS.events, id }), payload);
    if (isAxiosError) throw response;

    yield put(eventsActions.updateEvent.success(data));
  } catch (error: any) {
    yield put(eventsActions.updateEvent.failure({ message: error.message, data: error.data }));
  }
}

function* getActiveEvents(): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getActiveEvents, { api: APIS.events }));

    if (isAxiosError) throw response;
    const { count } = data;

    yield put(eventsActions.getActiveEvents.success({ count }));
  } catch (error: any) {
    yield put(eventsActions.getActiveEvents.failure({ message: error.message, data: error.data }));
  }
}

function* getEvent({ payload }: ReturnType<typeof eventsActions.getEvent.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getEvent, { api: APIS.events, id: payload }));

    if (isAxiosError) throw response;

    yield put(eventsActions.getEvent.success({ event: data }));
  } catch (error: any) {
    yield put(eventsActions.getEvent.failure({ message: error.message, data: error.data }));
  }
}

function* activateEvent({ payload }: ReturnType<typeof eventsActions.activateEvent.request>): SagaIterator {
  try {
    const { id, is_active } = payload;
    const action = is_active ? API_MAP.activateEvent : API_MAP.deactivateEvent;
    const { isAxiosError, response } = yield call(requestGenerator(action, { api: APIS.events, id }));
    if (isAxiosError) throw response;
    yield put(eventsActions.activateEvent.success({ id, is_running: is_active }));
    yield put(eventsActions.getActiveEvents.request());
  } catch (error: any) {
    yield put(eventsActions.activateEvent.failure({ message: error.message, data: error.data }));
  }
}

function* updateEventAudience({ payload }: ReturnType<typeof eventsActions.updateEventAudience.request>): SagaIterator {
  try {
    const id = payload.id;
    delete payload.id;
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateEventAudience, { api: APIS.events, id }), payload);
    if (isAxiosError) throw response;
    yield put(eventsActions.updateEventAudience.success(data));
  } catch (error: any) {
    yield put(eventsActions.updateEventAudience.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(eventsActions.getEventsList.request, getEventsList),
    takeEvery(eventsActions.getEventsLogsList.request, getEventsLogsList),
    takeEvery(eventsActions.createEvent.request, createEvent),
    takeEvery(eventsActions.updateEvent.request, updateEvent),
    takeEvery(eventsActions.activateEvent.request, activateEvent),
    takeEvery(eventsActions.getActiveEvents.request, getActiveEvents),
    takeEvery(eventsActions.getEvent.request, getEvent),
    takeEvery(eventsActions.updateEventAudience.request, updateEventAudience),
  ]);
}
