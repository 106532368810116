import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('ROLES/RESET')<undefined>();

const resetRolesList = createAction(
  'ROLES/RESET_LIST'
)<undefined>();

const getRoleGroupList = createAsyncAction(
  'ROLES/GET/REQUEST',
  'ROLES/GET/SUCCESS',
  'ROLES/GET/FAILURE',
)<any, any, any>();

const createRoleGroup = createAsyncAction(
  'ROLES/CREATE/REQUEST',
  'ROLES/CREATE/SUCCESS',
  'ROLES/CREATE/FAILURE',
)<any, any, any>();

const updateRoleGroup = createAsyncAction(
  'ROLES/UPDATE/REQUEST',
  'ROLES/UPDATE/SUCCESS',
  'ROLES/UPDATE/FAILURE',
)<any, any, any>();

const deleteRoleGroup = createAsyncAction(
  'ROLES/ACTIVATE/REQUEST',
  'ROLES/ACTIVATE/SUCCESS',
  'ROLES/ACTIVATE/FAILURE',
)<any, undefined, any>();

const resetOperationStatus = createAction('ROLES/RESET_OPERATION_STATUS')<string>();

export default {
  reset,
  resetRolesList,
  getRoleGroupList,
  createRoleGroup,
  updateRoleGroup,
  deleteRoleGroup,
  resetOperationStatus
} as const;
