import axios from 'axios';
import store from 'store';
import authActions from 'store/auth/actions';
import { refreshTokenUtil } from './utils/refreshToken';
import apiConfig from './apiConfig';

const baseURL = apiConfig.apiEndpoint;
const devicesBaseURL = apiConfig.devicesApiEndpoint;

export const http = axios.create({
  baseURL: `${baseURL}/management/api/v1`,
  withCredentials: true,
});

export const devicesHttp = axios.create({
  baseURL: `${devicesBaseURL}/devices/api/v1`,
  withCredentials: true,
});

export const dashboardHttp = axios.create({
  baseURL: `${devicesBaseURL}/dashboard/api/v1`,
  withCredentials: true,
});

export const eventsHttp = axios.create({
  baseURL: `${devicesBaseURL}/events/api/v1`,
  withCredentials: true,
});

http.interceptors.request.use(
  request => {
    return request
  }
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (+error.response?.status === 401) {
      if (error.response?.data?.message === 'Token expired') {
        return refreshTokenUtil(http, error).catch(() => {
          store.dispatch(authActions.setLoggedOut.request());
        });
      }
      store.dispatch(authActions.setLoggedOut.request());
    }
    // if (+error.response?.status === 403) {
    //   store.dispatch(authActions.setLoggedOut.request());
    // }

    return Promise.reject(error);
  },
);

devicesHttp.interceptors.request.use(
  request => {
    return request
  }
);

devicesHttp.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (+error.response?.status === 401) {
      if (error.response?.data?.message === 'Token expired') {
        return refreshTokenUtil(http, error).catch(() => {
          store.dispatch(authActions.setLoggedOut.request());
        });
      }
      store.dispatch(authActions.setLoggedOut.request());
    }
    // if (+error.response?.status === 403) {
    //   store.dispatch(authActions.setLoggedOut.request());
    // }

    return Promise.reject(error);
  },
);

dashboardHttp.interceptors.request.use(
  request => {
    return request
  }
);

dashboardHttp.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (+error.response?.status === 401) {
      if (error.response?.data?.message === 'Token expired') {
        return refreshTokenUtil(http, error).catch(() => {
          store.dispatch(authActions.setLoggedOut.request());
        });
      }
      store.dispatch(authActions.setLoggedOut.request());
    }
    // if (+error.response?.status === 403) {
    //   store.dispatch(authActions.setLoggedOut.request());
    // }

    return Promise.reject(error);
  },
);

eventsHttp.interceptors.request.use(
  request => {
    return request
  }
);

eventsHttp.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (+error.response?.status === 401) {
      if (error.response?.data?.message === 'Token expired') {
        return refreshTokenUtil(http, error).catch(() => {
          store.dispatch(authActions.setLoggedOut.request());
        });
      }
      store.dispatch(authActions.setLoggedOut.request());
    }
    // if (+error.response?.status === 403) {
    //   store.dispatch(authActions.setLoggedOut.request());
    // }

    return Promise.reject(error);
  },
);
