import { StateType } from 'typesafe-actions';

export type RootState = StateType<typeof import('./reducers').Firmwares>;

export interface AuthReducer {
  id: string;
  AccessToken: string;
  IPI: string;
  IdToken: string;
  RefreshToken: string;
  UPI: string;
  userLoggedOut?: boolean;
  email: string;
  firstName: string;
  lastName: string;
  isAuthenticated: boolean;
  authenticationStatus: ActionStatus;
  loginStatus: ActionStatus;
  resetPasswordStatus: ActionStatus;
  signUpStatus: ActionStatus;
  firstLoginStatus: ActionStatus;
  tokenValidationStatus: ActionStatus;
  isAuthenticating: boolean;
}

export interface AppReducer {
  isLeftSideMenuOpen: boolean;
}

export interface DashboardReducer {
  title: string;
  grafanaUID: string;
  isPublished: boolean;
  dashboardUrl: string;
  getDashboardStatus: ActionStatus;
  publishDashboardStatus: ActionStatus;
  updateDashboardStatus: ActionStatus;
  createMapWidgetStatus: ActionStatus;
  createBarWidgetStatus: ActionStatus;
  createPieWidgetStatus: ActionStatus;
  createLineWidgetStatus: ActionStatus;
  createTableWidgetStatus: ActionStatus;
}

export enum OperationStatus {
  INIT,
  SUCCESS,
  LOADING,
  FAILURE,
  PROCESS,
}
export interface OrganizationsReducer {
  organizations: Organization[];
  getOrganizationsListStatus: ActionStatus;
  createOrganizationStatus: ActionStatus;
  updateOrganizationStatus: ActionStatus;
}

export interface UsersReducer {
  users: User[];
  userNames: UserName[];
  numUsers: number;
  getUsersListStatus: ActionStatus;
  getUserNamesStatus: ActionStatus;
  createUserStatus: ActionStatus;
  updateUserStatus: ActionStatus;
  activateUserStatus: ActionStatus;
  inactivateUserStatus: ActionStatus;
}

export interface ClientsReducer {
  data: User[];
  count: number;
  getClientsListStatus: ActionStatus;
}

export interface AccountSettingsReducer {
  accountSettings: Organization | null;
  getAccountSettingsStatus: ActionStatus;
  updateAccountSettingsStatus: ActionStatus;
}

export interface UserProfileReducer {
  userProfile: UserProfile | null;
  userPermissions: Permission[];
  getUserProfileStatus: ActionStatus;
  updateUserProfileStatus: ActionStatus;
  getUserPermissionsStatus: ActionStatus;
}

export interface PermissionsReducer {
  permissions: Permission[];
  getPermissionsListStatus: ActionStatus;
}

export interface RolesReducer {
  roles: Role[];
  count: number;
  getRolesListStatus: ActionStatus;
  createRoleGroupStatus: ActionStatus;
  updateRoleGroupStatus: ActionStatus;
  deleteRoleGroupStatus: ActionStatus;
}

export type ActionStatus = {
  status: OperationStatus;
  errorMessage: string;
  errorData: any;
};

export interface Organization {
  id: string;
  name: string;
  address: string;
  phone_number: string;
  is_active: boolean;
  parent_tenant: string | null;
  admin_email: string;
}

export interface User {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  tenant_id: string;
  role: string;
  is_active: boolean;
  organization: string;
}

export interface UserName {
  id: string;
  full_name: string;
}

export interface Role {
  id: string;
  name_alias: string;
  name: string;
  permissions: Permission[];
  organizations: {
    id: string;
    name: string;
  }[];
  is_active: boolean;
  is_default: boolean;
}
export interface UserProfile {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  organization: {
    id: string;
    name: string;
  };
  role?: {
    id: number;
    name_alias: string;
  };
  is_iot_core: boolean;
}
export interface Permission {
  id: number;
  name: string;
  codename: string;
}
export interface LogOut {
  logOut: boolean;
}

export enum TelemetryFormat {
  Int = 'int',
  Float = 'float',
  String = 'string',
  Boolean = 'boolean',
}

export interface Telemetry {
  id: string;
  name: string;
  display_name: string;
  format: TelemetryFormat;
  description: string;
  measurement_init: string;
  telemetry_type: string;
  is_measurement: boolean;
  show_in_mobile: boolean;
  ui_component: any;
}

export interface ConfigurationUIComponent {
  id: string;
  key: string;
  description: string;
  is_range_required: boolean;
}

export interface ConfigurationExtendedData {
  min_value: number;
  max_value: number;
  default_value: string;
}

export interface Configuration {
  id: string;
  name: string;
  ui_component: ConfigurationUIComponent;
  extended_data: ConfigurationExtendedData;
  show_in_mobile: boolean;
  display_name: string;
}

export interface TelemetryName extends Telemetry {
  device_type: number;
}

export interface TelemetryNameOption extends TelemetryName {
  disabled?: boolean;
}

export interface TelemetryType {
  id: string;
  name: string;
}

export interface Type {
  id: number;
  name: string;
  description: string;
  mqtt_topic: string;
  status: boolean;
  telemetries?: Telemetry[];
  configurations?: Configuration[];
  is_published: boolean;
  protocol: string;
  image_path: string;
}

export interface Component {
  id: string;
  key: string;
  description: string;
  is_range_required: boolean;
}

export interface TypeName {
  id: number;
  thing_type_name: string;
  name: string;
}

export interface TypesReducer {
  types: Type[];
  typesNames: TypeName[];
  telemetries: Telemetry[];
  telemetryTypes: TelemetryType[];
  telemetryNames: TelemetryName[];
  telemetryNamesWidget: TelemetryName[];
  components:  Component[];
  count: number;
  getTypesListStatus: ActionStatus;
  getTypesNamesStatus: ActionStatus;
  getComponentsStatus: ActionStatus;
  currentType: Type | null;
  getCurrentTypeStatus: ActionStatus;
  publishStatus: ActionStatus;
  unpublishStatus: ActionStatus;
  copyTypeStatus: ActionStatus;
  createTypeStatus: ActionStatus;
  createTelemetryStatus: ActionStatus;
  createTelemetryTypeStatus: ActionStatus;
  createConfigurationStatus: ActionStatus;
  getTelemetryTypeListStatus: ActionStatus;
  editTelemetryStatus: ActionStatus;
  deleteTelemetryStatus: ActionStatus;
  updateTypeStatus: ActionStatus;
  updateConfigurationStatus: ActionStatus;
  getTelemetryNamesStatus: ActionStatus;
  getTelemetryNamesWidgetStatus: ActionStatus;
  getConfigurationStatus: ActionStatus;
  deleteConfigurationStatus: ActionStatus;
}

// export enum DeviceOfflineStatuses {
//   XTime = 'x time',
//   KeepAliveXTime = 'keep alive x time',
// }

export interface DeviceOwner {
  full_name: string;
  email: string;
}

export interface Device {
  id: number;
  device_id: string;
  name: string;
  comment: string;
  device_type: number;
  // offline_status: string;
  organization: string;
  organization_id: string;
  location: string;
  firmware: string;
  telemetries?: Telemetry[];
  is_active: boolean;
  serial_number: string | null;
  state: 'Online' | 'Offline';
  owner: DeviceOwner;
}

export interface DeviceGroup {
  id: string;
  name: string;
  update_date: string;
  is_active: boolean;
  devices: Device[];
  organization: any;
  device_type: any;
  firmware: any;
  type: string;
}

export interface BulkReport {
  id: number;
  start_time: Date;
  end_time?: Date;
  user: string;
  number_of_created?: number;
  number_of_updated?: number;
  number_of_failed?: number;
}

export interface DevicesReducer {
  devices: Device[];
  count: number;
  getDevicesListStatus: ActionStatus;
  filtersData: {
    organizations: { label: string; value: string }[];
    getOrganizations: ActionStatus;
    types: { label: string; value: string }[];
    getTypes: ActionStatus;
    firmwares: { label: string; value: string }[];
    getFirmwares: ActionStatus;
    groups: { label: string; value: string }[];
    getGroups: ActionStatus;
  };
  createDeviceStatus: ActionStatus;
  updateDeviceStatus: ActionStatus;
  currentDevice?: Device;
  getCurrentDeviceStatus: ActionStatus;
  getDeviceShadowStatus: ActionStatus;
  bulkReports: BulkReport[];
  getBulkReportsStatus: ActionStatus;
  bulkReportsCount: number;
  downloadBulkReportStatus: ActionStatus;
}

export interface DeviceGroupReducer {
  devicegroups: DeviceGroup[];
  count: number;
  currentGroup: DeviceGroup | null;
  getDeviceGroupListStatus: ActionStatus;
  createDeviceGroupStatus: ActionStatus;
  getDeviceGroupByIdStatus: ActionStatus;
  updateDeviceGroupStatus: ActionStatus;
}

export interface FotaFile {
  id: number;
  name: string;
  upload_time: Date;
  is_active: boolean;
  file_size: number;
  label: string;
  firmware_version: string;
  checksum: string;
}

export enum FotaJobStatuses {
  Progress = 'progress',
  Canceled = 'canceled',
  Completed = 'completed',
}

export interface FotaJobStatus {
  text: string;
  value: FotaJobStatuses;
  number_of_failed: number;
  number_of_succeeded: number;
}

export interface FotaJob {
  id: string;
  name: string;
  description: string;
  device_group: string;
  number_of_succeeded: number;
  number_of_failed: number;
  fota_file: string;
  created_at: Date;
  last_update: Date;
  status: FotaJobStatus;
}

export interface FotaReducer {
  fotaFiles: FotaFile[];
  fotaTemplates: FotaTemplate[];
  count: number;
  templateCount: number;
  getFotaFilesListStatus: ActionStatus;
  createFotaFileStatus: ActionStatus;
  downloadFotaFileStatus: ActionStatus;
  downloadFotaJobStatus: ActionStatus;
  updateFotaFileStatus: ActionStatus;
  fotaJobs: FotaJob[];
  jobsCount: number;
  currentFotaJob: FotaJob | null;
  currentFotaTemplate: FotaTemplate | null;
  getFotaJobsListStatus: ActionStatus;
  getFotaTemplatesListStatus: ActionStatus;
  getFotaTemplateByIdStatus: ActionStatus;
  updateFotaTemplateStatus: ActionStatus;
  createFotaJobStatus: ActionStatus;
  getFotaJobStatus: ActionStatus;
}

export interface FotaTemplate {
  id: string;
  name: string;
  created_at: Date;
  status: boolean;
  user: string;
  protocol: string;
  signature: string;
  job_run_type: string;
  original_hash_algorithm: string;
  original_encryption_algorithm: string;
  path_name_of_code_signing_certificate_on_device: string;
  path_name_of_file_on_device: string;
  job_timeout_configuration: number;
}

export interface EventItem {
  id: number;
  name: string;
  is_running: boolean;
  event_type: 'telemetry' | 'scheduler';
  device_type: string;
  event_log_level: EventType;
  created_at: string;
  target_group: boolean;
}

export interface Event extends EventItem {
  condition_telemetries: any[];
  condition_tree: any;
  device_type: TypeName;
  email_body: string | null;
  email_subject: string | null;
  iot_events_condition_template: string;
  notification_delay: number;
  notification_type: string[];
  organizations: { id: string; name: string }[];
  sms_body: string | null;
  push_body: string | null;
  tenant: { id: string; name: string }[];
  target_users: { user_id: string; full_name: string }[] | null;
}

export enum EventType {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
}

export interface EventsReducer {
  event: Event | null;
  events: EventItem[];
  numEvents: number;
  eventsLogs: Event[];
  numEventLogs: number;
  activeEvents: number;
  getEventStatus: ActionStatus;
  getEventsListStatus: ActionStatus;
  getEventsLogsListStatus: ActionStatus;
  createEventStatus: ActionStatus;
  updateEventStatus: ActionStatus;
  activateEventStatus: ActionStatus;
  updateEventAudienceStatus: ActionStatus;
}

export interface RouteItem {
  path: string;
  component: React.FC;
  permission?: Module | Module[],
}

export enum DeviceStates {
  telemetries = 'telemetries',
  properties = 'properties',
}

export enum Aggregations {
  last = 'Last value',
  avg = 'Avg',
  sum = 'Sum',
  count = 'Count',
  max = 'Max',
  min = 'Min',
}

export enum LineAggregations {
  raw = 'Raw data',
  avg = 'Avg',
  sum = 'Sum',
  count = 'Count',
  max = 'Max',
  min = 'Min',
}

export enum LineGrouping {
  month = 'Month',
  day = 'Day',
  hour = 'Hour',
}

export enum Module {
  dashboard = 'dashboards-permissions',
  roles = 'roles-permissions',
  device = 'device',
  fota = 'FOTA',
  organizations = 'organizations',
  users = 'users',
  eventsTemplates = 'event-templates',
  eventsInstances = 'event-instances',
  eventsAudience = 'event-audience',
}

export enum Operations {
  read = 'Read',
  write = 'Read&Write',
}
