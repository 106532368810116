import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator from 'http/utils/requestGenerator';
import organizationsActions from './actions';

function* getOrganizations(): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getOrganizations));
    if (isAxiosError) throw response;
    const { results } = data;
    yield put(organizationsActions.getOrganizations.success(results));
  } catch (error: any) {
    yield put(organizationsActions.getOrganizations.failure({ message: error.message, data: error.data }));
  }
}

function* createOrganization({ payload }: ReturnType<typeof organizationsActions.createOrganization.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createOrganization), payload);
    if (isAxiosError) throw response;
    yield put(organizationsActions.createOrganization.success(data));
  } catch (error: any) {
    yield put(organizationsActions.createOrganization.failure({ message: error.message, data: error.data }));
  }
}

function* updateOrganization({ payload }: ReturnType<typeof organizationsActions.updateOrganization.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateOrganization, { id: payload.id }), payload);
    if (isAxiosError) throw response;

    yield put(organizationsActions.updateOrganization.success(data));
  } catch (error: any) {

    yield put(organizationsActions.updateOrganization.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(organizationsActions.getOrganizations.request, getOrganizations),
    takeEvery(organizationsActions.createOrganization.request, createOrganization),
    takeEvery(organizationsActions.updateOrganization.request, updateOrganization),
  ]);
}
