import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator from 'http/utils/requestGenerator';
import accountSettingsActions from './actions';

function* updateAccountSettings({ payload }: ReturnType<typeof accountSettingsActions.updateAccountSettings.request>): SagaIterator {
  try {
    const id = payload.id;
    delete payload.id

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateAccountSettings, { id }), payload);
    if (isAxiosError) throw response;

    yield put(accountSettingsActions.updateAccountSettings.success(data));
  } catch (error: any) {
    yield put(accountSettingsActions.updateAccountSettings.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(accountSettingsActions.updateAccountSettings.request, updateAccountSettings),
  ]);
}
