const getConfig = (): any => {
  if (window.localStorage.getItem('credentials')) {
    return {
      headers: {
        authorization: JSON.parse(window.localStorage.getItem('credentials') as string)?.AccessToken,
        idtoken: JSON.parse(window.localStorage.getItem('credentials') as string)?.IdToken,
        ipi: JSON.parse(window.localStorage.getItem('credentials') as string)?.IPI,
        upi: JSON.parse(window.localStorage.getItem('credentials') as string)?.UPI,
      }
    }
  } else {
    return {
      headers: {}
    }
  }

};

export default getConfig;
