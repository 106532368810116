import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator, { APIS } from 'http/utils/requestGenerator';
import clientsActions from './actions';

function* getClientsList({ payload } : ReturnType<typeof clientsActions.getClientsList.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getClientsList, { api: APIS.default }, payload));
    if (isAxiosError) throw response;

    const { results, count } = data;

    yield put(clientsActions.getClientsList.success({ data: results, count }));
  } catch (error: any) {
    yield put(clientsActions.getClientsList.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(clientsActions.getClientsList.request, getClientsList),
  ]);
}
