import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';
import { OperationStatus, ClientsReducer } from '../types.d';

type ClientsActions = ActionType<typeof actions>;

const initialState: ClientsReducer = {
  data: [],
  count: 0,
  getClientsListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
};

const reducer = createReducer<ClientsReducer, ClientsActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
    errorData: {},
    },
  }))
  .handleAction(actions.resetClientsList, (state) => ({
    ...state,
    data: [],
    count: 0,
  }))
  .handleAction(actions.getClientsList.request, (state) => ({
    ...state,
    getClientsListStatus: {
      ...state.getClientsListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getClientsList.success, (state, { payload: { data, count } }) => ({
    ...state,
    data: [
      ...state.data,
      ...data
    ],
    count,
    getClientsListStatus: {
      ...state.getClientsListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getClientsList.failure, (state, { payload: error }) => ({
    ...state,
    getClientsListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }));

export default reducer;
