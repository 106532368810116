import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)'
  },
}));

export default useStyles;
