import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('DEVICES/RESET')<undefined>();

const getDevicesList = createAsyncAction(
  'DEVICES/GET/REQUEST',
  'DEVICES/GET/SUCCESS',
  'DEVICES/GET/FAILURE',
)<any, any, any>();

const resetDevicesList = createAction(
  'DEVICES/RESET_LIST'
)<undefined>();

const getTypesFilterData = createAsyncAction(
  'DEVICES/GET_TYPES_FILTER_DATA/REQUEST',
  'DEVICES/GET_TYPES_FILTER_DATA/SUCCESS',
  'DEVICES/GET_TYPES_FILTER_DATA/FAILURE',
)<any, any, any>();

const getOrganizationsFilterData = createAsyncAction(
  'DEVICES/GET_ORGANIZATION_FILTER_DATA/REQUEST',
  'DEVICES/GET_ORGANIZATION_FILTER_DATA/SUCCESS',
  'DEVICES/GET_ORGANIZATION_FILTER_DATA/FAILURE',
)<any, any, any>();

const getFirmwaresFilterData = createAsyncAction(
  'DEVICES/GET_FIRMWARES_FILTER_DATA/REQUEST',
  'DEVICES/GET_FIRMWARES_FILTER_DATA/SUCCESS',
  'DEVICES/GET_FIRMWARES_FILTER_DATA/FAILURE',
)<any, any, any>();

const getGroupsFilterData = createAsyncAction(
  'DEVICES/GET_GROUPS_FILTER_DATA/REQUEST',
  'DEVICES/GET_GROUPS_FILTER_DATA/SUCCESS',
  'DEVICES/GET_GROUPS_FILTER_DATA/FAILURE',
)<any, any, any>();

const createDevice = createAsyncAction(
  'DEVICES/CREATE/REQUEST',
  'DEVICES/CREATE/SUCCESS',
  'DEVICES/CREATE/FAILURE',
)<any, any, any>();

const updateDevice = createAsyncAction(
  'DEVICES/UPDATE/REQUEST',
  'DEVICES/UPDATE/SUCCESS',
  'DEVICES/UPDATE/FAILURE',
)<any, any, any>();

const getCurrentDevice = createAsyncAction(
  'DEVICES/GET_CURRENT/REQUEST',
  'DEVICES/GET_CURRENT/SUCCESS',
  'DEVICES/GET_CURRENT/FAILURE',
)<{ deviceId: string }, any, any>();

const getDeviceShadow = createAsyncAction(
  'DEVICES/GET_SHADOW/REQUEST',
  'DEVICES/GET_SHADOW/SUCCESS',
  'DEVICES/GET_SHADOW/FAILURE',
)<any, any, any>();

const resetCurrentDevice = createAction(
  'DEVICES/RESET_CURRENT_DEVICE'
)<undefined>();

const resetOperationStatus = createAction('DEVICES/RESET_OPERATION_STATUS')<any>();

const createBulkDevice = createAsyncAction(
  'DEVICES/BULK_CREATE/REQUEST',
  'DEVICES/BULK_CREATE/SUCCESS',
  'DEVICES/BULK_CREATE/FAILURE',
)<any, any, any>();

const getBulkReports = createAsyncAction(
  'DEVICES/BULK_REPORTS/REQUEST',
  'DEVICES/BULK_REPORTS/SUCCESS',
  'DEVICES/BULK_REPORTS/FAILURE',
)<any, any, any>();

const resetBulkReportsList = createAction(
  'DEVICES/RESET_BULK_REPORTS_LIST',
)<undefined>();

const downloadBulkReport = createAsyncAction(
  'DEVICES/BULK_REPORTS_DOWNLOAD/REQUEST',
  'DEVICES/BULK_REPORTS_DOWNLOAD/SUCCESS',
  'DEVICES/BULK_REPORTS_DOWNLOAD/FAILURE',
)<any, any, any>();

export default {
  reset,
  getDevicesList,
  resetDevicesList,
  getTypesFilterData,
  getOrganizationsFilterData,
  getFirmwaresFilterData,
  getGroupsFilterData,
  createDevice,
  updateDevice,
  getCurrentDevice,
  getDeviceShadow,
  resetCurrentDevice,
  resetOperationStatus,
  createBulkDevice,
  getBulkReports,
  resetBulkReportsList,
  downloadBulkReport,
} as const;
