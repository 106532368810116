import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import useStyles from './styles';

interface Props {
  className?: string;
}

const Spinner: FC<Props> = ({ className }: Props) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.root, className)}>
      <CircularProgress color="primary" />
    </div>
  );
};

Spinner.defaultProps = {
  className: '',
};

export default Spinner;
