import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import requestGenerator, { APIS } from 'http/utils/requestGenerator';
import { API_MAP } from 'http/apiMap';
import deviceGroupActions from './actions';

function* getDeviceGroups({ payload }: ReturnType<typeof deviceGroupActions.getDeviceGroups.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getDeviceGroups, { api: APIS.devices }, params));

    if (isAxiosError) throw Error(response?.data?.error ?? "We can't connect, please try again");

    const { results, count } = data;
    yield put(deviceGroupActions.getDeviceGroups.success({ devicegroups: results, count }));
  } catch (error: any) {
    yield put(deviceGroupActions.getDeviceGroups.failure({ message: error.message, data: error.data }));
  }
}

function* createDeviceGroup({ payload }: ReturnType<typeof deviceGroupActions.createDeviceGroup.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createDeviceGroup, { api: APIS.devices }), payload);
    if (isAxiosError) throw response;

    yield put(deviceGroupActions.createDeviceGroup.success(data));
  } catch (error: any) {
    yield put(deviceGroupActions.createDeviceGroup.failure({ message: error.message, data: error.data }));
  }
}

function* getDeviceGroupById({ payload }: ReturnType<typeof deviceGroupActions.getDeviceGroupById.request>): SagaIterator {
  try {
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.getDeviceGroupById, { api: APIS.devices, id: payload }));
    if (isAxiosError) throw response;

    yield put(deviceGroupActions.getDeviceGroupById.success(data));
  } catch (error: any) {
    yield put(deviceGroupActions.getDeviceGroupById.failure({message: error.message, data: error.data}));
  }
}

function* updateDeviceGroup({ payload }: ReturnType<typeof deviceGroupActions.updateDeviceGroup.request>): SagaIterator {
  try {
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.updateDeviceGroup, { api: APIS.devices, id: payload.id }), payload);
    if (isAxiosError) throw response;

    yield put(deviceGroupActions.updateDeviceGroup.success(data));
  } catch (error: any) {
    yield put(deviceGroupActions.updateDeviceGroup.failure({message: error.message, data: error.data}));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(deviceGroupActions.getDeviceGroups.request, getDeviceGroups),
    takeEvery(deviceGroupActions.createDeviceGroup.request, createDeviceGroup),
    takeEvery(deviceGroupActions.getDeviceGroupById.request, getDeviceGroupById),
    takeEvery(deviceGroupActions.updateDeviceGroup.request, updateDeviceGroup),
  ]);
}
