import { createTheme } from '@material-ui/core/styles';
import { colors } from './styleVariables';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: colors.mainButtonColor,
      light: colors.disabledButtonColor,
      dark: colors.hoverButtonColor,
    },
    error: {
      main: colors.redError,
    },
    divider: colors.grey1,
    text: {
      secondary: colors.textSecondary,
    },
    background: {
      //paper: colors.orgBoxes
    }
  },
  typography: {
    fontFamily: ['Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1024,
      xl: 1920,
    },
  },
  spacing: 8,
  overrides: {
    MuiFilledInput: {
      root: {
        borderRadius: '2px',
        borderTopRightRadius: '2px',
        borderTopLeftRadius: '2px',
        border: `1px solid ${colors.grey2}`
      },
      input: {
        padding: '0 10px',

        '&:focus': {
          borderRadius: '2px',
        },
        '&:after': {
          borderBottom: 'none'
        }
      },
      multiline: {
        padding: '0 10px',
        lineHeight: '30px',
        background: 'transparent !important',

        '&:focus': {
          borderRadius: '2px',
        },
        '&:before, &:after': {
          borderBottom: 'none',
        },
        '&:hover': {
          '&:before': {
            borderBottom: 'none'
          }
        }
      }
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid #ddd'
      },
      indicator: {
        width: '200px!important',
        height: '5px',
        backgroundColor: colors.mainButtonColor
      },
      flexContainer: {
        width: '100%',
        justifyContent: 'space-between'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#000',
        paddingLeft: '0px',
      },
      wrapper: {
        justifyContent: 'flex-start',
        flexDirection: 'row'
      }
    },
    MuiInputBase: {
      input: {
        background: '#fff',
        padding: '0 10px',
        height: '40px',
        position: 'relative',
        textAlign: 'left',
        fontSize: '0.75rem',
      }
    },
    MuiInputLabel: {
      root: {
        position: 'static',
        color: '#000',
        fontWeight: 500,
        transform: 'none',
        marginBottom: '5px',
        fontSize: '0.75rem',

        '&$focused': {
          'color': '#000!important'
        }
      },
      formControl: {
        position: 'static',
        color: '#000',
        fontWeight: 500,
        transform: 'none',
        marginBottom: '5px',
        fontSize: '0.75rem',
      },
      asterisk: {
        color: colors.hoverButtonColor,
        fontWeight: 500,
        position: 'absolute',
        left: '-12px',
        fontSize: '1.125rem',
        '&$error': {
          color: colors.hoverButtonColor
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontWeight: 500
      }
    },
    MuiFormControl: {
      root: {
        display: 'flex'
      }
    },
    MuiLink: {
      root: {
        color: '#000',
        fontSize: '0.875rem',
        fontWeight: 500,

        '&:hover': {
          textDecoration: 'none',
        },
      }
    },
    MuiSelect: {
      root: {
        border: '1px solid #BDBDBD'
      },
      select: {
        borderRadius: '2px',
      },
      selectMenu: {
        height: '40px',
        lineHeight: '40px'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '0!important'
      },
      underline: {
        '&::after': {
          borderBottom: 'none'
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 50,
        height: '40px',
        fontSize: '0.875rem',
        whiteSpace: 'nowrap',
        textTransform: 'none',

        '&$disabled': {
          backgroundColor: colors.disabledButtonColor,
          boxShadow: 'none',
          color: '#fff'
        },
      },
    },
    MuiPaper: {
      root: {
        background: '#fff'
      },
      elevation8: {
        boxShadow: 'none',
        boxSizing: 'content-box',
        border: '1px solid #000',
        borderRadius: 0,
        borderTop: '1px solid #fff'
      }
    },
    MuiDrawer: {
      paper: {
        background: 'transparent',
        position: 'static',
      },
      paperAnchorDockedLeft: {
        borderRight: 'none'
      }
    },
    MuiTable: {
      root: {
        tableLayout: 'fixed',
      }
    },
    MuiTableHead: {
      root: {
        borderTop: '1px solid #E5E5E5',
        borderBottom: '1px solid #E5E5E5'
      }
    },
    MuiTableRow: {
      head: {
        borderTop: '1px solid #E5E5E5',
        borderBottom: '1px solid #E5E5E5'
      },
      root: {
        borderBottom: '1px solid #E5E5E5!important',

        '& [class*="actions"]': {
          visibility: 'hidden'
        },

        '&:hover': {
          background: 'rgba(237,241,246,0.5)',
          '& [class*="actions"]': {
            visibility: 'visible'
          },
          '& td.MuiTableCell-root': {
            whiteSpace: 'inherit',
          }
        }
      },

    },
    MuiTableCell: {
      head: {
        color: '#919699',
        fontSize: '1rem'
      },
      body: {
        cursor: 'pointer'
      },
      root: {
        borderBottom: 'transparent',
        color: '#000',
        fontSize: '1rem',
        fontFamily: 'Roboto',
        paddingLeft: 26,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
        textOverflow: 'ellipsis'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#000'
      },
      colorSecondary: {
        '&$checked': {
          color: colors.orgBoxes
        }
      }
    },
    MuiChip: {
      root: {
        height: '25px',
        backgroundColor: '#EDF1F6'
      },
      deleteIcon: {
        height: 14,
        margin: '0 3px 0 -6px'
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 430
      },
      paperWidthMd: {
        maxWidth: 788
      },
      paperWidthLg: {
        maxWidth: 1350,
      },
      paperWidthXl: {
        maxWidth: 1400
      }
    },
    MuiIconButton: {
      colorSecondary: {
        color: 'var(--conditionBuilderDeleteButtonText)' /* delete button foreground */,
        backgroundColor:
          'var(--conditionBuilderDeleteButtonBackground)' /* delete button backround */,
      },
    },
    MuiRadio: {
      root: {
        color: 'unset',
        backgroundColor: 'transparent',
        '&.Mui-checked': {
          color: 'unset !important',
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        color: '#919699',
        '&$checked': {
          color: colors.mainButtonColor,

          '& + $track': {
            backgroundColor: '#EDF1F6',
            opacity: 1,
          }
        }
      },
      track: {
        backgroundColor: '#EDF1F6',
        opacity: 1,
      },
    }
  },
});
export default MuiTheme;
