import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Router } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { RootState } from 'store/reducers';
import authActions from 'store/auth/actions';
import PrivateRoute from './PrivateRoute';
import history from './history';
import Spinner from '../components/Spinner';

const Auth = lazy(() => import('pages/Auth'));
const PrivateWrapper = lazy(() => import('pages/PrivateWrapper'));
const NotFound = lazy(() => import('../pages/NotFound'));

const AppRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticating } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const parsedCredentinals = JSON.parse(window.localStorage.getItem('credentials') as string);
    if (parsedCredentinals?.AccessToken) {
      dispatch(
        authActions.setLoggedIn.success({
          ...parsedCredentinals,
        }),
      );
    }
    dispatch(authActions.setAuthenticating(false));
  }, [dispatch, isAuthenticating]);

  useEffect(() => {
    const handleCredentialsChanged = (e: { key: any; oldValue: any; newValue: any; }) => {
      if (e.key === 'credentials' && e.oldValue && !e.newValue) {
        dispatch(authActions.setLoggedOut.request())
      }
    }
    window.addEventListener('storage', handleCredentialsChanged)
    return function cleanup() {
      window.removeEventListener('storage', handleCredentialsChanged)
    }
  }, []);

  if (isAuthenticating) {
    return null;
  }

  return (
    <>
      <Router history={history}>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/auth" component={Auth} />
            <PrivateRoute path="*" component={PrivateWrapper} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AppRouter;
