import { createAction, createAsyncAction } from 'typesafe-actions';

const reset = createAction('ORGANIZATIONS/RESET')<undefined>();

const getOrganizations = createAsyncAction(
  'ORGANIZATIONS/GET/REQUEST',
  'ORGANIZATIONS/GET/SUCCESS',
  'ORGANIZATIONS/GET/FAILURE',
)<undefined, any, any>();

const createOrganization = createAsyncAction(
  'ORGANIZATIONS/CREATE/REQUEST',
  'ORGANIZATIONS/CREATE/SUCCESS',
  'ORGANIZATIONS/CREATE/FAILURE',
)<any, any, any>();

const getOrganization = createAsyncAction(
  'ORGANIZATIONS/GET_ORGANIZATION/REQUEST',
  'ORGANIZATIONS/GET_ORGANIZATION/SUCCESS',
  'ORGANIZATIONS/GET_ORGANIZATION/FAILURE',
)<any, any, any>();

const updateOrganization = createAsyncAction(
  'ORGANIZATIONS/UPDATE/REQUEST',
  'ORGANIZATIONS/UPDATE/SUCCESS',
  'ORGANIZATIONS/UPDATE/FAILURE',
)<any, any, any>();

const resetOperationStatus = createAction('ORGANIZATIONS/RESET_OPERATION_STATUS')<any>();

export default {
  reset,
  getOrganizations,
  createOrganization,
  getOrganization,
  updateOrganization,
  resetOperationStatus,
} as const;
