import { ActionType, createReducer } from 'typesafe-actions';
import actions from './actions';
import { OperationStatus, Telemetry, Type, TypesReducer } from '../types.d';

type TypesActions = ActionType<typeof actions>;

const initialState: TypesReducer = {
  types: [],
  typesNames: [],
  telemetries: [],
  telemetryTypes: [],
  telemetryNames: [],
  telemetryNamesWidget: [],
  components: [],
  count: 0,
  getTypesListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getTypesNamesStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getComponentsStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  publishStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  unpublishStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  currentType: null,
  getCurrentTypeStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  copyTypeStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createTypeStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createTelemetryStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  createConfigurationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  editTelemetryStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  deleteTelemetryStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateTypeStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateConfigurationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  createTelemetryTypeStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  getTelemetryTypeListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getTelemetryNamesStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getTelemetryNamesWidgetStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getConfigurationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  deleteConfigurationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
};

const reducer = createReducer<TypesReducer, TypesActions>(initialState)
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {}
    },
  }))
  .handleAction(actions.publishType.request, (state) => ({
    ...state,
    publishStatus: {
      ...state.publishStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.publishType.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? { ...state.currentType, is_published: true } : null,
    types: state.types.map((row: Type) => {
      if (row.id === payload) {
        row.is_published = true;
      }
      return row;
    }),
    publishStatus: {
      ...state.publishStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.publishType.failure, (state, { payload: error }) => ({
    ...state,
    publishStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.resetTypesList, (state) => ({
    ...state,
    types: [],
    getTypesListStatus: initialState.getTypesListStatus,
  }))
  .handleAction(actions.getTypesList.request, (state) => ({
    ...state,
    getTypesListStatus: {
      ...state.getTypesListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getTypesList.success, (state, { payload: { types, count } }) => ({
    ...state,
    types: [
      ...state.types,
      ...types,
    ],
    count,
    getTypesListStatus: {
      ...state.getTypesListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getTypesList.failure, (state, { payload: error }) => ({
    ...state,
    getTypesListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getTypesNames.request, (state) => ({
    ...state,
    getTypesNamesStatus: {
      ...state.getTypesNamesStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getTypesNames.success, (state, { payload: { types } }) => ({
    ...state,
    typesNames: [...types],
    getTypesNamesStatus: {
      ...state.getTypesNamesStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getTypesNames.failure, (state, { payload: error }) => ({
    ...state,
    getTypesNamesStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createTelemetryType.failure, (state, { payload: error }) => ({
    ...state,
    createTelemetryTypeStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createTelemetryType.request, (state) => ({
    ...state,
    createTelemetryTypeStatus: {
      ...state.getTelemetryTypeListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.createTelemetryType.success, (state, { payload }) => ({
    ...state,
    telemetryTypes: [
      ...state.telemetryTypes,
      payload,
    ],
    createTelemetryTypeStatus: {
      ...state.getTelemetryTypeListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getTelemetryTypeList.failure, (state, { payload: error }) => ({
    ...state,
    getTelemetryTypeListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getTelemetryTypeList.request, (state) => ({
    ...state,
    getTelemetryTypeListStatus: {
      ...state.getTelemetryTypeListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getTelemetryTypeList.success, (state, { payload }) => ({
    ...state,
    telemetryTypes: [
      ...state.telemetryTypes,
      ...payload,
    ],
    getTelemetryTypeListStatus: {
      ...state.getTelemetryTypeListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.resetCurrentTypeTelemetry, (state) => ({
    ...state,
    currentType: state.currentType ? {
      ...state.currentType,
      telemetryTypes: [],
    } : null,
  }))
  .handleAction(actions.copyType.request, (state) => ({
    ...state,
    copyTypeStatus: {
      ...state.copyTypeStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.copyType.success, (state, { payload }) => ({
    ...state,
    copyTypeStatus: {
      ...state.copyTypeStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.copyType.failure, (state, { payload: error }) => ({
    ...state,
    copyTypeStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createType.request, (state) => ({
    ...state,
    createTypeStatus: {
      ...state.createTypeStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createType.success, (state, { payload }) => ({
    ...state,
    types: [
      ...state.types,
      payload
    ],
    count: state.count + 1,
    createTypeStatus: {
      ...state.createTypeStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.createType.failure, (state, { payload: error }) => ({
    ...state,
    createTypeStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getTypeById.request, (state) => ({
    ...state,
    getCurrentTypeStatus: {
      ...state.getCurrentTypeStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getTypeById.success, (state, { payload }) => ({
    ...state,
    currentType: payload,
    getCurrentTypeStatus: {
      ...state.getCurrentTypeStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.getTypeById.failure, (state, { payload: error }) => ({
    ...state,
    getCurrentTypeStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateType.request, (state) => ({
    ...state,
    updateTypeStatus: {
      ...state.updateTypeStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.updateType.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? { ...state.currentType, ...payload } : undefined,
    types: state.types.map((row: Type) => {
      if (row.id === payload.id) {
        row = { ...row, ...payload };
      }
      return row;
    }),
    updateTypeStatus: {
      ...state.updateTypeStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.updateType.failure, (state, { payload: error }) => ({
    ...state,
    updateTypeStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createTelemetry.request, (state) => ({
    ...state,
    createTelemetryStatus: {
      ...state.createTelemetryStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createTelemetry.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? {
      ...state.currentType,
      telemetries: [ ...state.currentType.telemetries || [], payload ]
    } : null,
    createTelemetryStatus: {
      ...state.createTelemetryStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.createTelemetry.failure, (state, { payload: error }) => ({
    ...state,
    createTelemetryStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.editTelemetry.request, (state) => ({
    ...state,
    editTelemetryStatus: {
      ...state.editTelemetryStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.editTelemetry.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? {
      ...state.currentType,
      telemetries: state.currentType.telemetries?.map((el: Telemetry) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      })
    } : null,
    editTelemetryStatus: {
      ...state.editTelemetryStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.editTelemetry.failure, (state, { payload: error }) => ({
    ...state,
    editTelemetryStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.deleteTelemetry.request, (state) => ({
    ...state,
    deleteTelemetryStatus: {
      ...state.deleteTelemetryStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.deleteTelemetry.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? {
      ...state.currentType,
      telemetries: state.currentType.telemetries?.filter(row => row.id !== payload.id)
    } : null,
    deleteTelemetryStatus: {
      ...state.deleteTelemetryStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.deleteTelemetry.failure, (state, { payload: error }) => ({
    ...state,
    deleteTelemetryStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getTelemetriesNames.request, (state) => ({
    ...state,
    getTelemetryNamesStatus: {
      ...state.getTelemetryNamesStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getTelemetriesNames.success, (state, { payload }) => ({
    ...state,
    telemetryNames: [...payload],
    getTelemetryNamesStatus: {
      ...state.getTelemetryTypeListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getTelemetriesNames.failure, (state, { payload: error }) => ({
    ...state,
    getTelemetryNamesStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getTelemetriesNamesWidget.request, (state) => ({
    ...state,
    getTelemetryNamesWidgetStatus: {
      ...state.getTelemetryNamesStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getTelemetriesNamesWidget.success, (state, { payload }) => ({
    ...state,
    telemetryNamesWidget: payload,
    getTelemetryNamesWidgetStatus: {
      ...state.getTelemetryNamesWidgetStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getTelemetriesNamesWidget.failure, (state, { payload: error }) => ({
    ...state,
    getTelemetryNamesWidgetStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getComponents.request, (state) => ({
    ...state,
    getComponentsStatus: {
      ...state.getComponentsStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getComponents.success, (state, { payload }) => ({
    ...state,
    components: payload,
    getComponentsStatus: {
      ...state.getComponentsStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getComponents.failure, (state, { payload: error }) => ({
    ...state,
    getComponentsStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createConfiguration.request, (state) => ({
    ...state,
    createConfigurationStatus: {
      ...state.createConfigurationStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createConfiguration.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? {
      ...state.currentType,
      configurations: [ ...state.currentType.configurations || [], payload ]
    } : null,
    createConfigurationStatus: {
      ...state.createConfigurationStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.createConfiguration.failure, (state, { payload: error }) => ({
    ...state,
    createConfigurationStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getConfiguration.request, (state) => ({
    ...state,
    getConfigurationStatus: {
      ...state.getConfigurationStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getConfiguration.success, (state) => ({
    ...state,
    getConfigurationStatus: {
      ...state.getConfigurationStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getConfiguration.failure, (state, { payload: error }) => ({
    ...state,
    getConfigurationStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateConfiguration.request, (state) => ({
    ...state,
    updateConfigurationStatus: {
      ...state.updateConfigurationStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.updateConfiguration.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? {
      ...state.currentType,
      configurations: state.currentType.configurations?.map(row => {
        if(row.id === payload.id){
          row = payload;
        }
        return row;
      })
    } : null,
    updateConfigurationStatus: {
      ...state.updateConfigurationStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.updateConfiguration.failure, (state, { payload: error }) => ({
    ...state,
    updateConfigurationStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.deleteConfiguration.request, (state) => ({
    ...state,
    deleteConfigurationStatus: {
      ...state.deleteConfigurationStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.deleteConfiguration.success, (state, { payload }) => ({
    ...state,
    currentType: state.currentType ? {
      ...state.currentType,
      configurations: state.currentType.configurations?.filter(row => row.id !== payload.id)
    } : null,
    deleteConfigurationStatus: {
      ...state.deleteConfigurationStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.deleteConfiguration.failure, (state, { payload: error }) => ({
    ...state,
    deleteConfigurationStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.resetTelemetriesNames, (state) => ({
    ...state,
    telemetryNames: [],
    getTelemetryNamesStatus: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {}
    }
  }))
  .handleAction(actions.resetTelemetriesNamesWidget, (state) => ({
    ...state,
    telemetryNamesWidget: [],
    getTelemetryNamesWidgetStatus: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {}
    }
  }))
  .handleAction(actions.resetCurrentType, (state) => ({
    ...state,
    currentType: null,
    getCurrentTypeStatus: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {}
    }
  }))
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }));

export default reducer;
