import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import configuredStore from 'store';
import AppRouter from 'router';
import './index.scss';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { I18nextProvider } from 'react-i18next';
import client from './apollo/client';
import i18n from './i18n';
import theme from './stylesConfig/theme';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configuredStore}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <AppRouter />
            </MuiPickersUtilsProvider>
          </I18nextProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
