import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('TYPES/RESET')<undefined>();

const getTypesList = createAsyncAction(
  'TYPES/GET/REQUEST',
  'TYPES/GET/SUCCESS',
  'TYPES/GET/FAILURE',
)<any, any, any>();

const getTypesNames = createAsyncAction(
  'TYPES/NAMES/REQUEST',
  'TYPES/NAMES/SUCCESS',
  'TYPES/NAMES/FAILURE',
)<any, any, any>();

const resetTypesList = createAction(
  'TYPES/RESET_LIST'
)<undefined>();

const resetCurrentTypeTelemetry = createAction(
  'TYPES/RESET_CURRENT_TYPE_TELEMETRY'
)<undefined>();

const copyType = createAsyncAction(
  'TYPES/COPY/REQUEST',
  'TYPES/COPY/SUCCESS',
  'TYPES/COPY/FAILURE',
)<any, any, any>();

const createType = createAsyncAction(
  'TYPES/CREATE/REQUEST',
  'TYPES/CREATE/SUCCESS',
  'TYPES/CREATE/FAILURE',
)<any, any, any>();

const publishType = createAsyncAction(
  'TYPES/PUBLISH/REQUEST',
  'TYPES/PUBLISH/SUCCESS',
  'TYPES/PUBLISH/FAILURE',
)<any, any, any>();

const unpublishType = createAsyncAction(
  'TYPES/UNPUBLISH/REQUEST',
  'TYPES/UNPUBLISH/SUCCESS',
  'TYPES/UNPUBLISH/FAILURE',
)<{ typeId: string }, any, any>();

const createTelemetry = createAsyncAction(
  'TYPES/CREATE_TELEMETRY/REQUEST',
  'TYPES/CREATE_TELEMETRY/SUCCESS',
  'TYPES/CREATE_TELEMETRY/FAILURE',
)<any, any, any>();

const editTelemetry = createAsyncAction(
  'TYPES/EDIT_TELEMETRY/REQUEST',
  'TYPES/EDIT_TELEMETRY/SUCCESS',
  'TYPES/EDIT_TELEMETRY/FAILURE',
)<any, any, any>();

const deleteTelemetry = createAsyncAction(
  'TYPES/DELETE_TELEMETRY/REQUEST',
  'TYPES/DELETE_TELEMETRY/SUCCESS',
  'TYPES/DELETE_TELEMETRY/FAILURE',
)<any, any, any>();

const getTypeById = createAsyncAction(
  'TYPES/GET_TYPE/REQUEST',
  'TYPES/GET_TYPE/SUCCESS',
  'TYPES/GET_TYPE/FAILURE',
)<any, any, any>();

const updateType = createAsyncAction(
  'TYPES/UPDATE_TYPE/REQUEST',
  'TYPES/UPDATE_TYPE/SUCCESS',
  'TYPES/UPDATE_TYPE/FAILURE',
)<any, any, any>();

const createTelemetryType = createAsyncAction(
  'TYPES/CREATE_TELEMETRY_TYPE/REQUEST',
  'TYPES/CREATE_TELEMETRY_TYPE/SUCCESS',
  'TYPES/CREATE_TELEMETRY_TYPE/FAILURE',
)<any, any, any>();

const getTelemetryTypeList = createAsyncAction(
  'TYPES/GET_TELEMETRY_TYPE/REQUEST',
  'TYPES/GET_TELEMETRY_TYPE/SUCCESS',
  'TYPES/GET_TELEMETRY_TYPE/FAILURE',
)<any, any, any>();

const getTelemetriesNames = createAsyncAction(
  'TYPES/GET_TELEMETRIES_NAMES/REQUEST',
  'TYPES/GET_TELEMETRIES_NAMES/SUCCESS',
  'TYPES/GET_TELEMETRIES_NAMES/FAILURE',
)<any, any, any>();

const getTelemetriesNamesWidget = createAsyncAction(
  'TYPES/GET_TELEMETRIES_NAMES_WIDGET/REQUEST',
  'TYPES/GET_TELEMETRIES_NAMES_WIDGET/SUCCESS',
  'TYPES/GET_TELEMETRIES_NAMES_WIDGET/FAILURE',
)<any, any, any>();

const getComponents = createAsyncAction(
  'COMPONENTS/GET/REQUEST',
  'COMPONENTS/GET/SUCCESS',
  'COMPONENTS/GET/FAILURE',
)<any, any, any>();

const createConfiguration = createAsyncAction(
  'CONFIGURATION/CREATE/REQUEST',
  'CONFIGURATION/CREATE/SUCCESS',
  'CONFIGURATION/CREATE/FAILURE',
)<any, any, any>();

const getConfiguration = createAsyncAction(
  'CONFIGURATION/GET/REQUEST',
  'CONFIGURATION/GET/SUCCESS',
  'CONFIGURATION/GET/FAILURE',
)<any, any, any>();

const updateConfiguration = createAsyncAction(
  'CONFIGURATION/UPDATE/REQUEST',
  'CONFIGURATION/UPDATE/SUCCESS',
  'CONFIGURATION/UPDATE/FAILURE',
)<any, any, any>();

const deleteConfiguration = createAsyncAction(
  'CONFIGURATION/DELETE/REQUEST',
  'CONFIGURATION/DELETE/SUCCESS',
  'CONFIGURATION/DELETE/FAILURE',
)<any, any, any>();

const resetTelemetriesNames = createAction('TYPES/RESET_TELEMETRIES_NAMES')<undefined>();

const resetTelemetriesNamesWidget = createAction('TYPES/RESET_TELEMETRIES_NAMES_WIDGET')<undefined>();

const resetCurrentType = createAction('TYPES/RESET_CURRENT_TYPE')<undefined>();

const resetOperationStatus = createAction('TYPES/RESET_OPERATION_STATUS')<any>();

export default {
  reset,
  getTypesList,
  getTypesNames,
  resetTypesList,
  resetOperationStatus,
  resetCurrentTypeTelemetry,
  copyType,
  createType,
  publishType,
  unpublishType,
  createTelemetry,
  getTypeById,
  updateType,
  createTelemetryType,
  getTelemetryTypeList,
  editTelemetry,
  deleteTelemetry,
  getTelemetriesNames,
  getTelemetriesNamesWidget,
  getComponents,
  createConfiguration,
  getConfiguration,
  updateConfiguration,
  deleteConfiguration,
  resetTelemetriesNames,
  resetTelemetriesNamesWidget,
  resetCurrentType,
} as const;
