import { combineReducers } from 'redux';
import authReducer from './auth';
import organizationsReducer from './organizations';
import usersReducer from './users';
import appReducer from './app';
import typesReducer from './deviceTypes/index';
import rolesReducer from './roles';
import permisssionsReducer from './permisssions';
import accountSettingsReducer from './settings';
import userProfile from './userProfile';
import devicesReducer from './devices';
import deviceGroupReducer from './devicegroups';
import fotaReducer from './fota';
import dashboardSaga from './dashboard';
import eventsSaga from './events';
import clientsReducer from './clients';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  dashboard: dashboardSaga,
  organizations: organizationsReducer,
  users: usersReducer,
  clients: clientsReducer,
  types: typesReducer,
  roles: rolesReducer,
  permisssions: permisssionsReducer,
  accountSettings: accountSettingsReducer,
  userProfile: userProfile,
  devices: devicesReducer,
  deviceGroups: deviceGroupReducer,
  fota: fotaReducer,
  events: eventsSaga,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
