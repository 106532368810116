import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('CLIENTS/RESET')<undefined>();

const resetOperationStatus = createAction('CLIENTS/RESET_OPERATION_STATUS')<any>();

const resetClientsList = createAction(
  'CLIENTS/RESET_USERS_LIST'
)<undefined>();

const getClientsList = createAsyncAction(
  'CLIENTS/GET/REQUEST',
  'CLIENTS/GET/SUCCESS',
  'CLIENTS/GET/FAILURE',
)<any, any, any>();

export default {
  reset,
  getClientsList,
  resetClientsList,
  resetOperationStatus,
} as const;
