export type ApiMapItem = {
  url: string;
  method: RequestType;
  useMock?: boolean;
  mock?: any
};

export enum RequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete'
}
export interface Params {
  [type: string]: number | boolean | undefined | string;
}
