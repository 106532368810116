import {FotaFile, FotaReducer, FotaTemplate, OperationStatus} from '../types.d';
import {ActionType, createReducer} from 'typesafe-actions';
import actions from './actions';

type FotaActions = ActionType<typeof actions>;

const initialState: FotaReducer = {
  fotaFiles: [],
  fotaTemplates: [],
  count: 0,
  templateCount: 0,
  getFotaFilesListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  createFotaFileStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  downloadFotaFileStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  updateFotaFileStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  fotaJobs: [],
  jobsCount: 0,
  currentFotaJob: null,
  currentFotaTemplate: null,
  getFotaJobsListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createFotaJobStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  getFotaJobStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  downloadFotaJobStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  getFotaTemplatesListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  getFotaTemplateByIdStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  updateFotaTemplateStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  }
};

const reducer = createReducer<FotaReducer, FotaActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetList, (state, { payload: listName }) => ({
    ...state,
    [listName]: []
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
    errorData: {},
    },
  }))
  .handleAction(actions.getFotaList.request, (state) => ({
    ...state,
    getDevicesListStatus: {
      ...state.getFotaFilesListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getFotaList.success, (state, {payload: {fotaFiles, count}}) => ({
    ...state,
    fotaFiles: [
      ...state.fotaFiles,
      ...fotaFiles,
    ],
    count,
    getFotaFilesListStatus: {
      ...state.getFotaFilesListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getFotaList.failure, (state, {payload: error}) => ({
    ...state,
    getFotaFilesListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createFotaFile.request, (state) => ({
    ...state,
    createFotaFileStatus: {
      ...state.createFotaFileStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createFotaFile.success, (state, {payload}) => ({
    ...state,
    fotaFiles: [...state.fotaFiles, payload],
    count: state.count + 1,
    createFotaFileStatus: {
      ...state.createFotaFileStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.createFotaFile.failure, (state, {payload: error}) => ({
    ...state,
    createFotaFileStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.downloadFotaFile.request, (state) => ({
    ...state,
    downloadFotaFileStatus: {
      ...state.downloadFotaFileStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.downloadFotaFile.success, (state) => ({
    ...state,
    downloadFotaFileStatus: {
      ...state.downloadFotaFileStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.downloadFotaFile.failure, (state, {payload: error}) => ({
    ...state,
    downloadFotaFileStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.updateFotaFile.request, (state) => ({
    ...state,
    updateFotaFileStatus: {
      ...state.updateFotaFileStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.updateFotaFile.success, (state, { payload }) => ({
    ...state,
    fotaFiles: state.fotaFiles.map((row: FotaFile) => {
      if(row.id === payload.id){
        row = payload;
      }
      return row;
    }),
    updateFotaFileStatus: {
      ...state.updateFotaFileStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.updateFotaFile.failure, (state, {payload: error}) => ({
    ...state,
    updateFotaFileStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.resetFotaFileList, (state) => ({
    ...state,
    fotaFiles: [],
    count: 0,
    getFotaFilesListStatus: initialState.getFotaFilesListStatus,
  }))
  .handleAction(actions.resetFotaJobsList, (state) => ({
    ...state,
    fotaJobs: [],
    jobsCount: 0,
    getFotaJobsListStatus: initialState.getFotaJobsListStatus,
  }))
  .handleAction(actions.getFotaJobs.request, (state) => ({
    ...state,
    getFotaJobsListStatus: {
      ...state.getFotaJobsListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getFotaJobs.success, (state, {payload: {fotaJobs, count}}) => ({
    ...state,
    fotaJobs: [
      ...state.fotaJobs,
      ...fotaJobs,
    ],
    jobsCount: count,
    getFotaJobsListStatus: {
      ...state.getFotaJobsListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getFotaJobs.failure, (state, {payload: error}) => ({
    ...state,
    getFotaJobsListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createFotaJob.request, (state) => ({
    ...state,
    createFotaJobStatus: {
      ...state.createFotaJobStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createFotaJob.success, (state, {payload}) => ({
    ...state,
    fotaJobs: [...state.fotaJobs, payload],
    jobsCount: state.jobsCount + 1,
    createFotaJobStatus: {
      ...state.createFotaJobStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.createFotaJob.failure, (state, {payload: error}) => ({
    ...state,
    createFotaJobStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getFotaJobById.request, (state) => ({
    ...state,
    getFotaJobStatus: {
      ...state.getFotaJobStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getFotaJobById.success, (state, {payload}) => ({
    ...state,
    currentFotaJob: payload,
    getFotaJobStatus: {
      ...state.getFotaJobStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.getFotaJobById.failure, (state, {payload: error}) => ({
    ...state,
    getFotaJobStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.downloadFotaJob.request, (state) => ({
    ...state,
    downloadFotaJobStatus: {
      ...state.downloadFotaJobStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.downloadFotaJob.success, (state) => ({
    ...state,
    downloadFotaJobStatus: {
      ...state.downloadFotaJobStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.downloadFotaJob.failure, (state, {payload: error}) => ({
    ...state,
    downloadFotaJobStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.resetCurrentFotaJob, (state) => ({
    ...state,
    currentFotaJob: null,
  }))
  .handleAction(actions.getFotaTemplates.request, (state) => ({
    ...state,
    getFotaTemplatesListStatus: {
      ...state.getFotaTemplatesListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getFotaTemplates.success, (state, {payload: {fotaTemplates, count}}) => ({
    ...state,
    fotaTemplates: [
      ...state.fotaTemplates,
      ...fotaTemplates,
    ],
    templateCount: count,
    getFotaTemplatesListStatus: {
      ...state.getFotaTemplatesListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getFotaTemplates.failure, (state, {payload: error}) => ({
    ...state,
    getFotaTemplatesListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getFotaTemplateById.request, (state) => ({
    ...state,
    getFotaTemplateByIdStatus: {
      ...state.getFotaTemplateByIdStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getFotaTemplateById.success, (state, { payload }) => ({
    ...state,
    currentFotaTemplate: payload,
    getFotaTemplateByIdStatus: {
      ...state.getFotaTemplateByIdStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.getFotaTemplateById.failure, (state, { payload: error }) => ({
    ...state,
    getFotaTemplateByIdStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateFotaTemplate.request, (state) => ({
    ...state,
    updateFotaTemplateStatus: {
      ...state.updateFotaTemplateStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.updateFotaTemplate.success, (state, { payload }) => ({
    ...state,
    currentFotaTemplate: payload,
    fotaTemplates: state.fotaTemplates.map((row: FotaTemplate) => {
      if(row.id === payload.id){
        row = payload;
      }
      return row;
    }),
    updateFotaTemplateStatus: {
      ...state.updateFotaTemplateStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.updateFotaTemplate.failure, (state, { payload: error }) => ({
    ...state,
    updateFotaTemplateStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.resetCurrentTemplate, (state) => ({
    ...state,
    currentFotaTemplate: null,
  }))
  .handleAction(actions.resetFotaTemplatesList, (state) => ({
    ...state,
    fotaTemplates: [],
    templateCount: 0,
    getFotaTemplatesListStatus: initialState.getFotaTemplatesListStatus,
  }));

export default reducer;
