import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('DEVICES/GROUPS/RESET')<undefined>();

const resetDeviceGroupList = createAction(
  'DEVICE_GROUPS/RESET_LIST'
)<undefined>();

const resetOperationStatus = createAction('DEVICE_GROUP/RESET_OPERATION_STATUS')<any>();

const getDeviceGroups = createAsyncAction(
  'DEVICE_GROUPS/GET/REQUEST',
  'DEVICE_GROUPS/GET/SUCCESS',
  'DEVICE_GROUPS/GET/FAILURE'
)<any, any, any>();

const createDeviceGroup = createAsyncAction(
  'DEVICE_GROUP/CREATE/REQUEST',
  'DEVICE_GROUP/CREATE/SUCCESS',
  'DEVICE_GROUP/CREATE/FAILURE'
)<any, any, any>();

const getDeviceGroupById = createAsyncAction(
  'DEVICE_GROUP/GET/REQUEST',
  'DEVICE_GROUP/GET/SUCCESS',
  'DEVICE_GROUP/GET/FAILURE'
)<any, any, any>();

const updateDeviceGroup = createAsyncAction(
  'DEVICE_GROUP/UPDATE/REQUEST',
  'DEVICE_GROUP/UPDATE/SUCCESS',
  'DEVICE_GROUP/UPDATE/FAILURE'
)<any, any, any>();

const resetCurrenDeviceGroup = createAction('DEVICE_GROUP/RESET')<undefined>();

export default {
  reset,
  resetDeviceGroupList,
  resetOperationStatus,
  getDeviceGroups,
  createDeviceGroup,
  getDeviceGroupById,
  updateDeviceGroup,
  resetCurrenDeviceGroup,
} as const;
