import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('FOTA/RESET')<undefined>();

const getFotaList = createAsyncAction(
  'FOTA/GET/REQUEST',
  'FOTA/GET/SUCCESS',
  'FOTA/GET/FAILURE',
)<any, any, any>();

const resetList = createAction(
  'FOTA/RESET_LIST'
)<any>();

const resetOperationStatus = createAction(
  'FOTA/RESET_OPERATION_STATUS',
)<any>();

const createFotaFile = createAsyncAction(
  'FOTA/CREATE/REQUEST',
  'FOTA/CREATE/SUCCESS',
  'FOTA/CREATE/FAILURE',
)<any, any, any>();

const downloadFotaFile = createAsyncAction(
  'FOTA/DOWNLOAD/REQUEST',
  'FOTA/DOWNLOAD/SUCCESS',
  'FOTA/DOWNLOAD/FAILURE',
)<any, any, any>();

const updateFotaFile = createAsyncAction(
  'FOTA/UPDATE/REQUEST',
  'FOTA/UPDATE/SUCCESS',
  'FOTA/UPDATE/FAILURE',
)<any, any, any>();

const resetFotaFileList = createAction('FOTA_FILE/RESET')<undefined>();

const getFotaJobs = createAsyncAction(
  'FOTA_JOB/GET/REQUEST',
  'FOTA_JOB/GET/SUCCESS',
  'FOTA_JOB/GET/FAILURE',
)<any, any, any>();

const resetFotaJobsList = createAction(
  'FOTA_JOB/RESET_LIST',
)<undefined>();

const createFotaJob = createAsyncAction(
  'FOTA_JOB/CREATE/REQUEST',
  'FOTA_JOB/CREATE/SUCCESS',
  'FOTA_JOB/CREATE/FAILURE',
)<any, any, any>();

const getFotaJobById = createAsyncAction(
  'FOTA_JOB/GET_BY_ID/REQUEST',
  'FOTA_JOB/GET_BY_ID/SUCCESS',
  'FOTA_JOB/GET_BY_ID/FAILURE',
)<any, any, any>();

const downloadFotaJob = createAsyncAction(
  'FOTA_JOB/DOWNLOAD/REQUEST',
  'FOTA_JOB/DOWNLOAD/SUCCESS',
  'FOTA_JOB/DOWNLOAD/FAILURE',
)<any, any, any>();

const resetCurrentFotaJob = createAction('FOTA_JOB/RESET')<undefined>();

const getFotaTemplates = createAsyncAction(
  'FOTA_TEMPLATES/GET/REQUEST',
  'FOTA_TEMPLATES/GET/SUCCESS',
  'FOTA_TEMPLATES/GET/FAILURE',
)<any, any, any>();

const getFotaTemplateById = createAsyncAction(
  'FOTA_TEMPLATE_BY_ID/GET/REQUEST',
  'FOTA_TEMPLATE_BY_ID/GET/SUCCESS',
  'FOTA_TEMPLATE_BY_ID/GET/FAILURE',
)<any, any, any>();

const updateFotaTemplate = createAsyncAction(
  'FOTA_TEMPLATE/UPDATE/REQUEST',
  'FOTA_TEMPLATE/UPDATE/SUCCESS',
  'FOTA_TEMPLATE/UPDATE/FAILURE',
)<any, any, any>();

const resetCurrentTemplate = createAction('FOTA_TEMPLATE/RESET')<undefined>();

const resetFotaTemplatesList = createAction('FOTA_TEMPLATES/RESET')<undefined>();

export default {
  reset,
  getFotaList,
  resetList,
  resetOperationStatus,
  createFotaFile,
  downloadFotaFile,
  updateFotaFile,
  resetFotaFileList,
  getFotaJobs,
  resetFotaJobsList,
  createFotaJob,
  getFotaJobById,
  downloadFotaJob,
  resetCurrentFotaJob,
  getFotaTemplates,
  getFotaTemplateById,
  updateFotaTemplate,
  resetCurrentTemplate,
  resetFotaTemplatesList,
} as const;
