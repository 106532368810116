import { generatePath } from 'react-router-dom';
import { ApiMapItem, Params } from 'http/apiTypes';
import { http, devicesHttp, dashboardHttp, eventsHttp } from 'http/index';
import getConfig from './getConfig';

export enum APIS {
  default = 'default',
  devices = 'devices',
  dashboard = 'dashboard',
  events = 'events',
}

const requestGenerator = (
  apiMapRequest: ApiMapItem,
  params?: Params,
  queryParams?: Params
) => {
  let path = generatePath(apiMapRequest.url, params);
  if (!path.endsWith('/')) path = `${path}/`;
  let axiosInstance = http;

  switch (params?.api) {
    case APIS.devices:
      axiosInstance = devicesHttp;
      break;
    case APIS.dashboard:
      axiosInstance = dashboardHttp;
      break;
    case APIS.events:
      axiosInstance = eventsHttp;
      break;
    default:
      axiosInstance = http;
      break;
  }

  return async (payload?: any) => {
    try {
      const response = await axiosInstance({
        headers: getConfig().headers,
        method: apiMapRequest.method,
        params: queryParams,
        data: payload,
        url: path,
      });
      return response;
    } catch (error: any) {
      return {
        isAxiosError: error.isAxiosError,
        response: {
          message:
            typeof error.response?.data === 'object'
              ? error.response?.data?.detail
              : "We can't connect, please try again",
          data:
            typeof error.response?.data === 'object'
              ? error.response?.data
              : {},
        },
      };
    }
  };
};

export default requestGenerator;
