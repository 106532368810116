import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';
import { OperationStatus, UserProfileReducer } from '../types.d';

type UserProfileActions = ActionType<typeof actions>;

const initialState: UserProfileReducer = {
  userProfile: null,
  userPermissions: JSON.parse(window.localStorage.getItem('permissions') as string) || [],
  getUserProfileStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateUserProfileStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getUserPermissionsStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  }
};

const reducer = createReducer<UserProfileReducer, UserProfileActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState,
    userPermissions: [],
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status:OperationStatus.INIT,
      errorMessage: '',
    errorData: {}
    },
  }))
  .handleAction(actions.getUserProfile.request, (state) => ({
    ...state,
    getUserProfileStatus: {
      ...state.getUserProfileStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getUserProfile.success, (state, { payload: userProfile }) => ({
    ...state,
    userProfile,
    getUserProfileStatus: {
      ...state.getUserProfileStatus,
      status: OperationStatus.SUCCESS
    }
  }))
  .handleAction(actions.getUserProfile.failure, (state, { payload: error }) => ({
    ...state,
    getUserProfileStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.updateUserProfile.request, (state) => ({
    ...state,
    updateUserProfileStatus: {
      ...state.updateUserProfileStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.updateUserProfile.success, (state, { payload: userProfile }) => ({
    ...state,
    userProfile,
    updateUserProfileStatus: {
      ...state.updateUserProfileStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.updateUserProfile.failure, (state, { payload: error }) => ({
    ...state,
    updateUserProfileStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getUserPermissions.request, (state) => ({
    ...state,
    getUserPermissionsStatus: {
      ...state.getUserPermissionsStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getUserPermissions.success, (state, { payload: userPermissions }) => ({
    ...state,
    userPermissions,
    getUserPermissionsStatus: {
      ...state.getUserPermissionsStatus,
      status: OperationStatus.SUCCESS
    }
  }))
  .handleAction(actions.getUserPermissions.failure, (state, { payload: error }) => ({
    ...state,
    getUserPermissionsStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))

export default reducer;
