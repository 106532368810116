import { ActionType, createReducer } from 'typesafe-actions';
import actions from './actions';
import { DashboardReducer, OperationStatus } from '../types.d';

type DashboardActions = ActionType<typeof actions>;

const initialState: DashboardReducer = {
  title: '',
  grafanaUID: '',
  isPublished: false,
  dashboardUrl: '',
  getDashboardStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  publishDashboardStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  updateDashboardStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createMapWidgetStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createBarWidgetStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createPieWidgetStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createLineWidgetStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createTableWidgetStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  }
};

const reducer = createReducer<DashboardReducer, DashboardActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState,
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
    errorData: {},
    },
  }))
  .handleAction(actions.getDashboard.request, (state) => ({
    ...state,
    getDashboardStatus: {
      ...state.getDashboardStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.getDashboard.success, (state, { payload: { title, link, grafana_uid, is_published } }) => ({
    ...state,
    title,
    dashboardUrl: link,
    grafanaUID: grafana_uid,
    isPublished: is_published,
    getDashboardStatus: {
      ...state.getDashboardStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.getDashboard.failure, (state, { payload: error }) => ({
    ...state,
    getDashboardStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.publishDashboard.request, (state) => ({
    ...state,
    publishDashboardStatus: {
      ...state.publishDashboardStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.publishDashboard.success, (state) => ({
    ...state,
    isPublished: true,
    publishDashboardStatus: {
      ...state.publishDashboardStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.publishDashboard.failure, (state, { payload: error }) => ({
    ...state,
    publishDashboardStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateDashboard.request, (state, { payload: { title } }) => ({
    ...state,
    title,
    updateDashboardStatus: {
      ...state.updateDashboardStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.updateDashboard.success, (state, { payload: { title } }) => ({
    ...state,
    title,
    updateDashboardStatus: {
      ...state.updateDashboardStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.updateDashboard.failure, (state, { payload: { error, title } }) => ({
    ...state,
    title,
    updateDashboardStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createMapWidget.request, (state) => ({
    ...state,
    createMapWidgetStatus: {
      ...state.createMapWidgetStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.createMapWidget.success, (state) => ({
    ...state,
    isPublished: false,
    createMapWidgetStatus: {
      ...state.createMapWidgetStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.createMapWidget.failure, (state, { payload: error }) => ({
    ...state,
    createMapWidgetStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createBarWidget.request, (state) => ({
    ...state,
    createBarWidgetStatus: {
      ...state.createBarWidgetStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.createBarWidget.success, (state) => ({
    ...state,
    isPublished: false,
    createBarWidgetStatus: {
      ...state.createBarWidgetStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.createBarWidget.failure, (state, { payload: error }) => ({
    ...state,
    createBarWidgetStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createPieWidget.request, (state) => ({
    ...state,
    createPieWidgetStatus: {
      ...state.createPieWidgetStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.createPieWidget.success, (state) => ({
    ...state,
    isPublished: false,
    createPieWidgetStatus: {
      ...state.createPieWidgetStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.createPieWidget.failure, (state, { payload: error }) => ({
    ...state,
    createPieWidgetStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createLineWidget.request, (state) => ({
    ...state,
    createLineWidgetStatus: {
      ...state.createLineWidgetStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.createLineWidget.success, (state) => ({
    ...state,
    isPublished: false,
    createLineWidgetStatus: {
      ...state.createLineWidgetStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.createLineWidget.failure, (state, { payload: error }) => ({
    ...state,
    createLineWidgetStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  })).handleAction(actions.createTableWidget.request, (state) => ({
    ...state,
    createTableWidgetStatus: {
      ...state.createTableWidgetStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.createTableWidget.success, (state) => ({
    ...state,
    isPublished: false,
    createTableWidgetStatus: {
      ...state.createTableWidgetStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.createTableWidget.failure, (state, { payload: error }) => ({
    ...state,
    createTableWidgetStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }));

export default reducer;
