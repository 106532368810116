import { createAction, createAsyncAction } from 'typesafe-actions';

const reset = createAction('DASHBOARD/RESET')<undefined>();

const resetOperationStatus = createAction('DASHBOARD/RESET_OPERATION_STATUS')<any>();

const getDashboard = createAsyncAction(
  'DASHBOARD/GET/REQUEST',
  'DASHBOARD/GET/SUCCESS',
  'DASHBOARD/GET/FAILURE',
)<undefined, any, any>();

const publishDashboard = createAsyncAction(
  'DASHBOARD/PUBLISH/REQUEST',
  'DASHBOARD/PUBLISH/SUCCESS',
  'DASHBOARD/PUBLISH/FAILURE',
)<undefined, undefined, any>();

const updateDashboard = createAsyncAction(
  'DASHBOARD/UPDATE/REQUEST',
  'DASHBOARD/UPDATE/SUCCESS',
  'DASHBOARD/UPDATE/FAILURE',
)<any, any, any>();

const createMapWidget = createAsyncAction(
  'DASHBOARD/CREATE_MAP/REQUEST',
  'DASHBOARD/CREATE_MAP/SUCCESS',
  'DASHBOARD/CREATE_MAP/FAILURE',
)<any, any, any>();

const createBarWidget = createAsyncAction(
  'DASHBOARD/CREATE_BAR/REQUEST',
  'DASHBOARD/CREATE_BAR/SUCCESS',
  'DASHBOARD/CREATE_BAR/FAILURE',
)<any, any, any>();

const createPieWidget = createAsyncAction(
  'DASHBOARD/CREATE_PIE/REQUEST',
  'DASHBOARD/CREATE_PIE/SUCCESS',
  'DASHBOARD/CREATE_PIE/FAILURE',
)<any, any, any>();

const createLineWidget = createAsyncAction(
  'DASHBOARD/CREATE_LINE/REQUEST',
  'DASHBOARD/CREATE_LINE/SUCCESS',
  'DASHBOARD/CREATE_LINE/FAILURE',
)<any, any, any>();

const createTableWidget = createAsyncAction(
  'DASHBOARD/CREATE_TABLE/REQUEST',
  'DASHBOARD/CREATE_TABLE/SUCCESS',
  'DASHBOARD/CREATE_TABLE/FAILURE',
)<any, any, any>();

export default {
  reset,
  getDashboard,
  publishDashboard,
  updateDashboard,
  createMapWidget,
  createBarWidget,
  createPieWidget,
  createLineWidget,
  createTableWidget,
  resetOperationStatus,
} as const;
