import { createAction } from 'typesafe-actions';

const toggleMenu = createAction('APP/TOGGLE_MENU')<undefined>();

const reset = createAction('APP/RESET')<undefined>();

export default {
  reset,
  toggleMenu,
} as const;
