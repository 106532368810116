import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('EVENTS/RESET')<undefined>();

const getEvent = createAsyncAction(
  'EVENT/GET/REQUEST',
  'EVENT/GET/SUCCESS',
  'EVENT/GET/FAILURE',
)<any, any, any>();

const resetEvent = createAction('EVENT/RESET')<undefined>();

const getEventsList = createAsyncAction(
  'EVENTS/GET/REQUEST',
  'EVENTS/GET/SUCCESS',
  'EVENTS/GET/FAILURE',
)<any, any, any>();

const resetEventsList = createAction(
  'EVENTS/RESET_LIST'
)<undefined>();

const getEventsLogsList = createAsyncAction(
  'EVENTS_LOGS/GET/REQUEST',
  'EVENTS_LOGS/GET/SUCCESS',
  'EVENTS_LOGS/GET/FAILURE',
)<any, any, any>();

const resetEventsLogsList = createAction(
  'EVENTS_LOGS/RESET_LIST'
)<undefined>();

const createEvent = createAsyncAction(
  'EVENT/CREATE/REQUEST',
  'EVENT/CREATE/SUCCESS',
  'EVENT/CREATE/FAILURE',
)<any, any, any>();

const updateEvent = createAsyncAction(
  'EVENT/UPDATE/REQUEST',
  'EVENT/UPDATE/SUCCESS',
  'EVENT/UPDATE/FAILURE',
)<any, any, any>();

const activateEvent = createAsyncAction(
  'EVENT/ACTIVATE/REQUEST',
  'EVENT/ACTIVATE/SUCCESS',
  'EVENT/ACTIVATE/FAILURE',
)<any, any, any>();

const getActiveEvents = createAsyncAction(
  'EVENTS/ACTIVE/REQUEST',
  'EVENTS/ACTIVE/SUCCESS',
  'EVENTS/ACTIVE/FAILURE',
)<undefined, any, any>();

const updateEventAudience = createAsyncAction(
  'EVENTS/AUDIENCE_UPDATE/REQUEST',
  'EVENTS/AUDIENCE_UPDATE/SUCCESS',
  'EVENTS/AUDIENCE_UPDATE/FAILURE',
)<any, any, any>();

const resetOperationStatus = createAction('EVENTS/RESET_OPERATION_STATUS')<any>();

export default {
  reset,
  getEventsList,
  resetEventsList,
  getEventsLogsList,
  resetEventsLogsList,
  createEvent,
  updateEvent,
  activateEvent,
  resetOperationStatus,
  getActiveEvents,
  resetEvent,
  getEvent,
  updateEventAudience,
} as const;
