import { OperationStatus, OrganizationsReducer } from '../types.d';
import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';

type OrganizationsActions = ActionType<typeof actions>;

const initialState: OrganizationsReducer = {
  organizations: [],
  getOrganizationsListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  createOrganizationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateOrganizationStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
};

const reducer = createReducer<OrganizationsReducer, OrganizationsActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: OperationStatus.INIT,
  }))
  .handleAction(actions.getOrganizations.request, (state) => ({
    ...state,
    getOrganizationsListStatus: {
      ...state.getOrganizationsListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getOrganizations.success, (state, { payload: organizations }) => ({
    ...state,
    organizations,
    getOrganizationsListStatus: {
      ...state.getOrganizationsListStatus,
      status: OperationStatus.SUCCESS
    }
  }))
  .handleAction(actions.getOrganizations.failure, (state, { payload: error }) => ({
    ...state,
    getOrganizationsListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.createOrganization.request, (state) => ({
    ...state,
    createOrganizationStatus: {
      ...state.createOrganizationStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.createOrganization.success, (state, { payload: organization }) => ({
    ...state,
    organizations: [
      ...state.organizations,
      organization
    ],
    createOrganizationStatus: {
      ...state.createOrganizationStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.createOrganization.failure, (state, { payload: error }) => ({
    ...state,
    createOrganizationStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateOrganization.request, (state) => ({
    ...state,
    updateOrganizationStatus: {
      ...state.updateOrganizationStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.updateOrganization.success, (state, { payload: organization }) => {
    const organizations = state.organizations.map(item => {
      if (item.id !== organization.id) {
        return item
      }
      return organization;
    });
    return {
      ...state,
      organizations,
      updateOrganizationStatus: {
        ...state.updateOrganizationStatus,
        status: OperationStatus.SUCCESS
      },
    };
  })
  .handleAction(actions.updateOrganization.failure, (state, { payload: error }) => ({
    ...state,
    updateOrganizationStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))

export default reducer;
