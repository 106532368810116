import { ActionType, createReducer } from 'typesafe-actions';
import actions from './actions';
import { OperationStatus, DeviceGroupReducer, DeviceGroup } from '../types.d';

type DeviceGroupActions = ActionType<typeof actions>;

const initialState: DeviceGroupReducer = {
  devicegroups: [],
  count: 0,
  currentGroup: null,
  getDeviceGroupListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  createDeviceGroupStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getDeviceGroupByIdStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateDeviceGroupStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
};

const reducer = createReducer<DeviceGroupReducer, DeviceGroupActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetDeviceGroupList, (state) => ({
    ...state,
    devicegroups: [],
    getDeviceGroupListStatus: initialState.getDeviceGroupListStatus,
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    },
  }))
  .handleAction(actions.getDeviceGroups.request, (state) => ({
    ...state,
    getDeviceGroupListStatus: {
      ...state.getDeviceGroupListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getDeviceGroups.success, (state, { payload: { devicegroups, count } }) => ({
    ...state,
    devicegroups: [...state.devicegroups, ...devicegroups],
    count,
    getDeviceGroupListStatus: {
      ...state.getDeviceGroupListStatus,
      status: OperationStatus.SUCCESS,
    },
  })
  )
  .handleAction(actions.getDeviceGroups.failure, (state, { payload: error }) => ({
    ...state,
    getDeviceGroupListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  })
  ).handleAction(actions.createDeviceGroup.request, (state) => ({
    ...state,
    createDeviceGroupStatus: {
      ...state.createDeviceGroupStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createDeviceGroup.success, (state, { payload }) => ({
    ...state,
    devicegroups: [
      ...state.devicegroups,
      payload
    ],
    createDeviceGroupStatus: {
      ...state.createDeviceGroupStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.createDeviceGroup.failure, (state, { payload: error }) => ({
    ...state,
    createDeviceGroupStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getDeviceGroupById.request, (state) => ({
    ...state,
    getDeviceGroupByIdStatus: {
      ...state.getDeviceGroupByIdStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getDeviceGroupById.success, (state, { payload }) => ({
    ...state,
    currentGroup: payload,
    getDeviceGroupByIdStatus: {
      ...state.getDeviceGroupByIdStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.getDeviceGroupById.failure, (state, { payload: error }) => ({
    ...state,
    getDeviceGroupByIdStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateDeviceGroup.request, (state) => ({
    ...state,
    updateDeviceGroupStatus: {
      ...state.updateDeviceGroupStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.updateDeviceGroup.success, (state, { payload }) => ({
    ...state,
    devicegroups: state.devicegroups.map((row: DeviceGroup) => {
      if(row.id === payload.id){
        row = payload;
      }
      return row;
    }),
    updateDeviceGroupStatus: {
      ...state.updateDeviceGroupStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.updateDeviceGroup.failure, (state, { payload: error }) => ({
    ...state,
    updateDeviceGroupStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.resetCurrenDeviceGroup, (state) => ({
    ...state,
    currentGroup: null,
  }))

export default reducer;
