import { OperationStatus, Role, RolesReducer } from '../types.d';
import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';

type RolesActions = ActionType<typeof actions>;

const initialState: RolesReducer = {
  roles: [],
  count: 0,
  getRolesListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  createRoleGroupStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateRoleGroupStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  deleteRoleGroupStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
};

const reducer = createReducer<RolesReducer, RolesActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => {
    return{
      ...state,
      [actionName]: {
        errorMessage: '',
        errorData: {},
        status: OperationStatus.INIT
      },
    }
  })
  .handleAction(actions.resetRolesList, (state) => ({
    ...state,
    roles: [],
    count: 0,
    getRolesListStatus: initialState.getRolesListStatus,
  }))
  .handleAction(actions.getRoleGroupList.request, (state: RolesReducer) => ({
    ...state,
    getRolesListStatus: {
      ...state.getRolesListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getRoleGroupList.success, (state: RolesReducer, { payload: { roles, count } }) => ({
    ...state,
    roles: [
      ...state.roles,
      ...roles,
    ],
    count,
    getRolesListStatus: {
      ...state.getRolesListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getRoleGroupList.failure, (state: RolesReducer, { payload: error }) => ({
    ...state,
    getRolesListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createRoleGroup.request, (state: RolesReducer) => ({
    ...state,
    createRoleGroupStatus: {
      ...state.createRoleGroupStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.createRoleGroup.success, (state: RolesReducer, { payload: role }) => ({
    ...state,
    roles: [
      ...state.roles,
      role
    ],
    createRoleGroupStatus: {
      ...state.createRoleGroupStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.createRoleGroup.failure, (state: RolesReducer, { payload: error }) => ({
    ...state,
    createRoleGroupStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateRoleGroup.request, (state: RolesReducer) => ({
    ...state,
    updateRoleGroupStatus: {
      ...state.updateRoleGroupStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.updateRoleGroup.success, (state: RolesReducer, { payload }) => ({
    ...state,
    roles: state.roles.map((row: Role) => {
      if(row.id === payload?.id){
        row = payload;
      }
      return row;
    }),
    updateRoleGroupStatus: {
      ...state.updateRoleGroupStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.updateRoleGroup.failure, (state: RolesReducer, { payload: error }) => ({
    ...state,
    updateRoleGroupStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.deleteRoleGroup.request, (state: RolesReducer) => ({
    ...state,
    deleteRoleGroupStatus: {
      ...state.deleteRoleGroupStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.deleteRoleGroup.success, (state: RolesReducer) => ({
    ...state,
    deleteRoleGroupStatus: {
      ...state.deleteRoleGroupStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.deleteRoleGroup.failure, (state: RolesReducer, { payload: error }) => ({
    ...state,
    deleteRoleGroupStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))

export default reducer;
