import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import appActions from './actions';
import authActions from '../auth/actions';
import dashboardActions from '../dashboard/actions';
import devicesActions from '../devices/actions';
import devicegroupsActions from '../devicegroups/actions';
import typesActions from '../deviceTypes/actions';
import fotaActions from '../fota/actions';
import organizationsActions from '../organizations/actions';
import permissionsActions from '../permisssions/actions';
import rolesActions from '../roles/actions';
import settingsActions from '../settings/actions';
import userProfileActions from '../userProfile/actions';
import usersActions from '../users/actions';

function* getDeviceGroups(): SagaIterator {
  try {
    yield put(authActions.reset());
    yield put(dashboardActions.reset());
    yield put(devicesActions.reset());
    yield put(devicegroupsActions.reset());
    yield put(typesActions.reset());
    yield put(fotaActions.reset());
    yield put(organizationsActions.reset());
    yield put(permissionsActions.reset());
    yield put(rolesActions.reset());
    yield put(settingsActions.reset());
    yield put(userProfileActions.reset());
    yield put(usersActions.reset());
  } catch (e: any) {
    throw Error(`Unable to reset app state: ${e}`);
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest(appActions.reset, getDeviceGroups),
  ]);
}
