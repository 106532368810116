import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';
import { OperationStatus, PermissionsReducer } from '../types.d';

type PermissionsActions = ActionType<typeof actions>;

const initialState: PermissionsReducer = {
  permissions: [],
  getPermissionsListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  }
};

const reducer = createReducer<PermissionsReducer, PermissionsActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.getPermissionsList.request, (state) => ({
    ...state,
    getPermissionsListStatus: {
      ...state.getPermissionsListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getPermissionsList.success, (state, { payload: { permissions } }) => ({
    ...state,
    permissions: [
      ...permissions
    ],
    getPermissionsListStatus: {
      ...state.getPermissionsListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getPermissionsList.failure, (state, { payload: error }) => ({
    ...state,
    getPermissionsListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))

export default reducer;
