import { ActionType, createReducer } from 'typesafe-actions';
import { EventsReducer, OperationStatus } from 'store/types.d';
import actions from './actions';

type EventsActions = ActionType<typeof actions>;

const initialState: EventsReducer = {
  event: null,
  events: [],
  numEvents: 0,
  eventsLogs: [],
  numEventLogs: 0,
  activeEvents: 0,
  getEventStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getEventsListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  getEventsLogsListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  createEventStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  updateEventStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  activateEventStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  updateEventAudienceStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  }
};

const reducer = createReducer<EventsReducer, EventsActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    },
  }))
  .handleAction(actions.resetEventsList, (state) => ({
    ...state,
    events: []
  }))
  .handleAction(actions.getEvent.request, (state) => ({
    ...state,
    getEventStatus: {
      ...state.getEventStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getEvent.success, (state, { payload: { event } }) => ({
    ...state,
    event,
    getEventStatus: {
      ...state.getEventStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getEvent.failure, (state, { payload: error }) => ({
    ...state,
    getEventStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.resetEvent, (state) => ({
    ...state,
    event: null,
  }))
  .handleAction(actions.getEventsList.request, (state) => ({
    ...state,
    getEventsListStatus: {
      ...state.getEventsListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getEventsList.success, (state, { payload: { events, count } }) => ({
    ...state,
    events: [
      ...state.events,
      ...events,
    ],
    numEvents: count,
    getEventsListStatus: {
      ...state.getEventsListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getEventsList.failure, (state, { payload: error }) => ({
    ...state,
    getEventsListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getEventsLogsList.request, (state) => ({
    ...state,
    getEventsListStatus: {
      ...state.getEventsListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getEventsLogsList.success, (state, { payload: { eventsLogs, count } }) => ({
    ...state,
    eventsLogs: [
      ...state.eventsLogs,
      ...eventsLogs,
    ],
    numEventLogs: count,
    getEventsLogsListStatus: {
      ...state.getEventsLogsListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getEventsLogsList.failure, (state, { payload: error }) => ({
    ...state,
    getEventsLogsListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  })).handleAction(actions.createEvent.request, (state) => ({
    ...state,
    createEventStatus: {
      ...state.createEventStatus,
      status: OperationStatus.PROCESS,
    }
  })).handleAction(actions.createEvent.success, (state) => ({
    ...state,
    createEventStatus: {
      ...state.createEventStatus,
      status: OperationStatus.SUCCESS,
    }
  })).handleAction(actions.createEvent.failure, (state, { payload: error }) => ({
    ...state,
    createEventStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  })).handleAction(actions.updateEvent.request, (state) => ({
    ...state,
    updateEventStatus: {
      ...state.updateEventStatus,
      status: OperationStatus.PROCESS,
    }
  })).handleAction(actions.updateEvent.success, (state) => ({
    ...state,
    updateEventStatus: {
      ...state.updateEventStatus,
      status: OperationStatus.SUCCESS,
    }
  })).handleAction(actions.updateEvent.failure, (state, { payload: error }) => ({
    ...state,
    updateEventStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  })).handleAction(actions.getActiveEvents.success, (state, { payload: { count } }) => ({
    ...state,
    activeEvents: count,
  })).handleAction(actions.activateEvent.request, (state) => ({
    ...state,
    activateEventStatus: {
      ...state.activateEventStatus,
      status: OperationStatus.PROCESS,
    }
  })).handleAction(actions.activateEvent.success, (state, { payload: { id, is_running } }) => {
    const index = state.events.findIndex(e => e.id === id);
    const nextEvents = [...state.events];
    nextEvents[index] = { ...nextEvents[index], is_running };

    const nextEvent = state.event;
    if (nextEvent) nextEvent.is_running = is_running;

    return ({
      ...state,
      event: nextEvent,
      events: nextEvents,
      activateEventStatus: {
        ...state.activateEventStatus,
        status: OperationStatus.SUCCESS,
      }
    });
  }).handleAction(actions.activateEvent.failure, (state, { payload: error }) => ({
    ...state,
    activateEventStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  })).handleAction(actions.updateEventAudience.request, (state) => ({
    ...state,
    updateEventAudienceStatus: {
      ...state.updateEventAudienceStatus,
      status: OperationStatus.PROCESS,
    }
  })).handleAction(actions.updateEventAudience.success, (state, { payload: data }) => ({
    ...state,
    event: { ...state.event, ...data },
    updateEventAudienceStatus: {
      ...state.updateEventAudienceStatus,
      status: OperationStatus.SUCCESS,
    }
  })).handleAction(actions.updateEventAudience.failure, (state, { payload: error }) => ({
    ...state,
    updateEventAudienceStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }));

export default reducer;
