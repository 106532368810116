import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator, { APIS } from 'http/utils/requestGenerator';
import rolesActions from './actions';

function* getRoleGroupsList({ payload }: ReturnType<typeof rolesActions.getRoleGroupList.request>): SagaIterator {
  try {
    let params = undefined;
    if (Object.keys(payload).length) {
      params = payload;
    }
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getRolesList,{ api: APIS.default }, params));

    if (isAxiosError) throw response;

    const { results, count } = data;

    yield put(rolesActions.getRoleGroupList.success({ roles: results, count }));
  } catch (error: any) {
    yield put(rolesActions.getRoleGroupList.failure({ message: error.message, data: error.data }));
  }
}

function* createRoleGroup({ payload }: ReturnType<typeof rolesActions.createRoleGroup.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createRole), payload);
    if (isAxiosError) throw response;

    yield put(rolesActions.createRoleGroup.success(data));
  } catch (error: any) {
    yield put(rolesActions.createRoleGroup.failure({ message: error.message, data: error.data }));
  }
}

function* updateRoleGroup({ payload }: ReturnType<typeof rolesActions.updateRoleGroup.request>): SagaIterator {
  try {
    const { id, ...requestData } = payload;

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.updateRole, { id }), requestData);
    if (isAxiosError) throw response;

    yield put(rolesActions.updateRoleGroup.success(data));
  } catch (error: any) {
    yield put(rolesActions.updateRoleGroup.failure({ message: error.message, data: error.data }));
  }
}

function* deleteRoleGroup({ payload }: ReturnType<typeof rolesActions.deleteRoleGroup.request>): SagaIterator {
  try {
    const { id, ...requestData } = payload;

    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.deleteRole, { id }), requestData);
    if (isAxiosError) throw response;

    yield put(rolesActions.updateRoleGroup.success(data));
  } catch (error: any) {
    yield put(rolesActions.updateRoleGroup.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(rolesActions.getRoleGroupList.request, getRoleGroupsList),
    takeEvery(rolesActions.createRoleGroup.request, createRoleGroup),
    takeEvery(rolesActions.updateRoleGroup.request, updateRoleGroup),
    takeEvery(rolesActions.deleteRoleGroup.request, deleteRoleGroup),
  ]);
}
