import {all, call, put, takeEvery, select} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {API_MAP} from 'http/apiMap';
import requestGenerator, {APIS} from 'http/utils/requestGenerator';
import fotaActions from './actions';

function* getFotaFilesList({payload}: ReturnType<typeof fotaActions.getFotaList.request>): SagaIterator {
  try {
    const { callBack, ...params} = payload;
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.getFotaFilesList, { api: APIS.devices }, params));
    if (isAxiosError) throw response;

    const {results, count} = data;
    if(callBack){
      callBack(count);
      return;
    }
    yield put(fotaActions.getFotaList.success({fotaFiles: results, count}));
  } catch (error: any) {
    yield put(fotaActions.getFotaList.failure({ message: error.message, data: error.data }));
  }
}

function* createFotaFile({ payload }: ReturnType<typeof fotaActions.createFotaFile.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP[payload.isIOTCore ? 'createFotaFile' : 'createNonIOTFotaFile'], { api: APIS.devices }), payload.data);
    if (isAxiosError) throw response;

    yield put(fotaActions.createFotaFile.success(data));
  } catch (error: any) {
    yield put(fotaActions.createFotaFile.failure({ message: error.message, data: error.data }));
  }
}

function* downloadFotaFile({ payload }: ReturnType<typeof fotaActions.downloadFotaFile.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.downloadFotaFile, { api: APIS.devices, id: payload.fileId }), payload);
    if (isAxiosError) throw response;
    yield put(fotaActions.downloadFotaFile.success(data));
    window.open(data);
  } catch (error: any) {
    yield put(fotaActions.downloadFotaFile.failure({ message: error.message, data: error.data }));
  }
}

function* updateFotaFile({ payload }: ReturnType<typeof fotaActions.updateFotaFile.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP[payload.isIOTCore ? 'updateFotaFile' : 'updateNonIOTFotaFile'], { api: APIS.devices, id: payload.data.id }), payload.data);
    if (isAxiosError) throw response;

    payload.callBack(data);
    yield put(fotaActions.updateFotaFile.success(data));
  } catch (error: any) {
    yield put(fotaActions.updateFotaFile.failure({ message: error.message, data: error.data }));
  }
}

function* getFotaJobsList({payload}: ReturnType<typeof fotaActions.getFotaJobs.request>): SagaIterator {
  try {
    const { callBack, ...params } = payload;
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.getFotaJobs, { api: APIS.devices }, params));
    if (isAxiosError) throw response;

    const {results, count} = data;
    if(callBack){
      callBack(count);
      return;
    }
    yield put(fotaActions.getFotaJobs.success({fotaJobs: results, count}));
  } catch (error: any) {
    yield put(fotaActions.getFotaJobs.failure({ message: error.message, data: error.data }));
  }
}

function* createFotaJob({ payload }: ReturnType<typeof fotaActions.createFotaJob.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.createFotaJob, { api: APIS.devices }), payload);
    if (isAxiosError) throw response;

    yield put(fotaActions.createFotaJob.success(data));
  } catch (error: any) {
    yield put(fotaActions.createFotaJob.failure({ message: error.message, data: error.data }));
  }
}

function* getFotaJobById({payload}: ReturnType<typeof fotaActions.getFotaJobById.request>): SagaIterator {
  try {
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.getFotaJobById, { api: APIS.devices, id: payload }));
    if (isAxiosError) throw response;

    yield put(fotaActions.getFotaJobById.success(data));
  } catch (error: any) {
    yield put(fotaActions.getFotaJobById.failure({ message: error.message, data: error.data }));
  }
}

function* downloadFotaJob({ payload }: ReturnType<typeof fotaActions.downloadFotaJob.request>): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.downloadFotaJob, { api: APIS.devices, id: payload.id }), payload);
    if (isAxiosError) throw response;
    yield put(fotaActions.downloadFotaJob.success(data));
    payload.callBack(data);
  } catch (error: any) {
    yield put(fotaActions.downloadFotaJob.failure({ message: error.message, data: error.data }));
  }
}

function* getFotaTemplates({payload}: ReturnType<typeof fotaActions.getFotaTemplates.request>): SagaIterator {
  try {
    const { callBack, ...params } = payload;
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.getFotaTemplates, { api: APIS.devices }, params));
    if (isAxiosError) throw response;

    const {results, count} = data;
    if(callBack){
      callBack(count);
      return;
    }
    yield put(fotaActions.getFotaTemplates.success({fotaTemplates: results, count}));
  } catch (error: any) {
    yield put(fotaActions.getFotaTemplates.failure({ message: error.message, data: error.data }));
  }
}

function* getFotaTemplateById({ payload }: ReturnType<typeof fotaActions.getFotaTemplateById.request>): SagaIterator {
  try {
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.getFotaTemplateById, { api: APIS.devices, id: payload }));
    if (isAxiosError) throw response;

    yield put(fotaActions.getFotaTemplateById.success(data));
  } catch (error: any) {
    yield put(fotaActions.getFotaTemplateById.failure({ message: error.message, data: error.data }));
  }
}

function* updateFotaTemplate({ payload }: ReturnType<typeof fotaActions.updateFotaTemplate.request>): SagaIterator {
  try {
    const {data, isAxiosError, response} = yield call(requestGenerator(API_MAP.updateFotaTemplate, { api: APIS.devices, id: payload.id }), payload);
    if (isAxiosError) throw response;

    yield put(fotaActions.updateFotaTemplate.success(data));
  } catch (error: any) {
    yield put(fotaActions.updateFotaTemplate.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(fotaActions.getFotaList.request, getFotaFilesList),
    takeEvery(fotaActions.createFotaFile.request, createFotaFile),
    takeEvery(fotaActions.downloadFotaFile.request, downloadFotaFile),
    takeEvery(fotaActions.updateFotaFile.request, updateFotaFile),
    takeEvery(fotaActions.getFotaJobs.request, getFotaJobsList),
    takeEvery(fotaActions.getFotaTemplates.request, getFotaTemplates),
    takeEvery(fotaActions.getFotaTemplateById.request, getFotaTemplateById),
    takeEvery(fotaActions.updateFotaTemplate.request, updateFotaTemplate),
    takeEvery(fotaActions.createFotaJob.request, createFotaJob),
    takeEvery(fotaActions.getFotaJobById.request, getFotaJobById),
    takeEvery(fotaActions.downloadFotaJob.request, downloadFotaJob),
  ]);
}
