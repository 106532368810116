import { ActionType, createReducer } from 'typesafe-actions';
import actions from './actions';
import { OperationStatus, DevicesReducer } from '../types.d';

type DevicesActions = ActionType<typeof actions>;

const initialState: DevicesReducer = {
  devices: [],
  count: 0,
  getDevicesListStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  filtersData: {
    organizations: [],
    getOrganizations: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    },
    types: [],
    getTypes: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    },
    firmwares: [],
    getFirmwares: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    },
    groups: [],
    getGroups: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    }
  },
  createDeviceStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateDeviceStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  currentDevice: undefined,
  getCurrentDeviceStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  getDeviceShadowStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  bulkReports: [],
  getBulkReportsStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  },
  bulkReportsCount: 0,
  downloadBulkReportStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {},
  }
};

const reducer = createReducer<DevicesReducer, DevicesActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: {
      status: OperationStatus.INIT,
      errorMessage: '',
      errorData: {},
    },
  }))
  .handleAction(actions.resetDevicesList, (state) => ({
    ...state,
    devices: [],
    getDevicesListStatus: initialState.getDevicesListStatus,
  }))
  .handleAction(actions.getDevicesList.request, (state) => ({
    ...state,
    getDevicesListStatus: {
      ...state.getDevicesListStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getDevicesList.success, (state, { payload: { devices, count } }) => ({
    ...state,
    devices: [
      ...state.devices,
      ...devices,
    ],
    count,
    getDevicesListStatus: {
      ...state.getDevicesListStatus,
      status: OperationStatus.SUCCESS,
    },
  }))
  .handleAction(actions.getDevicesList.failure, (state, { payload: error }) => ({
    ...state,
    getDevicesListStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getTypesFilterData.request, (state) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getTypes: {
        ...state.filtersData.getTypes,
        status: OperationStatus.PROCESS,
      }
    },
  }))
  .handleAction(actions.getTypesFilterData.success, (state, { payload }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      types: payload,
      getTypes: {
        ...state.filtersData.getTypes,
        status: OperationStatus.SUCCESS,
      }
    },
  }))
  .handleAction(actions.getTypesFilterData.failure, (state, { payload: error }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getTypes: {
        status: OperationStatus.FAILURE,
        errorMessage: error.message,
        errorData: error.data
      }
    },
  }))
  .handleAction(actions.getOrganizationsFilterData.request, (state) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getOrganizations: {
        ...state.filtersData.getOrganizations,
        status: OperationStatus.PROCESS,
      }
    },
  }))
  .handleAction(actions.getOrganizationsFilterData.success, (state, { payload }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      organizations: payload,
      getOrganizations: {
        ...state.filtersData.getOrganizations,
        status: OperationStatus.SUCCESS,
      }
    },
  }))
  .handleAction(actions.getOrganizationsFilterData.failure, (state, { payload: error }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getOrganizations: {
        status: OperationStatus.FAILURE,
        errorMessage: error.message,
        errorData: error.data
      }
    },
  }))
  .handleAction(actions.getFirmwaresFilterData.request, (state) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getFirmwares: {
        ...state.filtersData.getOrganizations,
        status: OperationStatus.PROCESS,
      }
    },
  }))
  .handleAction(actions.getFirmwaresFilterData.success, (state, { payload }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      firmwares: payload,
      getFirmwares: {
        ...state.filtersData.getFirmwares,
        status: OperationStatus.SUCCESS,
      }
    },
  }))
  .handleAction(actions.getFirmwaresFilterData.failure, (state, { payload: error }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getFirmwares: {
        status: OperationStatus.FAILURE,
        errorMessage: error.message,
        errorData: error.data
      }
    },
  }))
  .handleAction(actions.getGroupsFilterData.request, (state) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getGroups: {
        ...state.filtersData.getGroups,
        status: OperationStatus.PROCESS,
      }
    },
  }))
  .handleAction(actions.getGroupsFilterData.success, (state, { payload }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      groups: payload,
      getGroups: {
        ...state.filtersData.getGroups,
        status: OperationStatus.SUCCESS,
      }
    },
  }))
  .handleAction(actions.getGroupsFilterData.failure, (state, { payload: error }) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      getGroups: {
        status: OperationStatus.FAILURE,
        errorMessage: error.message,
        errorData: error.data
      }
    },
  }))
  .handleAction(actions.createDevice.request, (state) => ({
    ...state,
    createDeviceStatus: {
      ...state.createDeviceStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createDevice.success, (state, { payload }) => ({
    ...state,
    devices: [
      ...state.devices,
      payload
    ],
    createDeviceStatus: {
      ...state.createDeviceStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.createDevice.failure, (state, { payload: error }) => ({
    ...state,
    createDeviceStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.updateDevice.request, (state) => ({
    ...state,
    updateDeviceStatus: {
      ...state.updateDeviceStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.updateDevice.success, (state, { payload }) => ({
    ...state,
    currentDevice: payload,
    updateDeviceStatus: {
      ...state.updateDeviceStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.updateDevice.failure, (state, { payload: error }) => ({
    ...state,
    updateDeviceStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.resetCurrentDevice, (state) => ({
    ...state,
    currentDevice: initialState.currentDevice,
    getCurrentDeviceStatus: initialState.getCurrentDeviceStatus,
  }))
  .handleAction(actions.getCurrentDevice.request, (state) => ({
    ...state,
    getCurrentDeviceStatus: {
      ...state.getCurrentDeviceStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getCurrentDevice.success, (state, { payload }) => ({
    ...state,
    currentDevice: payload,
    getCurrentDeviceStatus: {
      ...state.getCurrentDeviceStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.getCurrentDevice.failure, (state, { payload: error }) => ({
    ...state,
    getCurrentDeviceStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getDeviceShadow.request, (state) => ({
    ...state,
    getDeviceShadowStatus: {
      ...state.getDeviceShadowStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getDeviceShadow.success, (state) => ({
    ...state,
    getDeviceShadowStatus: {
      ...state.getDeviceShadowStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.getDeviceShadow.failure, (state, { payload: error }) => ({
    ...state,
    getDeviceShadowStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.createBulkDevice.request, (state) => ({
    ...state,
    createDeviceStatus: {
      ...state.createDeviceStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.createBulkDevice.success, (state, { payload }) => ({
    ...state,
    devices: [
      ...state.devices,
      payload
    ],
    createDeviceStatus: {
      ...state.createDeviceStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.createBulkDevice.failure, (state, { payload: error }) => ({
    ...state,
    createDeviceStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.getBulkReports.request, (state) => ({
    ...state,
    getBulkReportsStatus: {
      ...state.getBulkReportsStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.getBulkReports.success, (state, { payload: { bulkReports, count } }) => ({
    ...state,
    bulkReports: [
      ...state.bulkReports,
      ...bulkReports,
    ],
    bulkReportsCount: count,
    getBulkReportsStatus: {
      ...state.getBulkReportsStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.getBulkReports.failure, (state, { payload: error }) => ({
    ...state,
    getBulkReportsStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))
  .handleAction(actions.resetBulkReportsList, (state) => ({
    ...state,
    bulkReports: initialState.bulkReports,
    bulkReportsCount: initialState.bulkReportsCount,
    getBulkReportsStatus: initialState.getBulkReportsStatus,
  }))
  .handleAction(actions.downloadBulkReport.request, (state) => ({
    ...state,
    downloadBulkReportStatus: {
      ...state.downloadBulkReportStatus,
      status: OperationStatus.PROCESS,
    }
  }))
  .handleAction(actions.downloadBulkReport.success, (state) => ({
    ...state,
    downloadBulkReportStatus: {
      ...state.downloadBulkReportStatus,
      status: OperationStatus.SUCCESS,
    }
  }))
  .handleAction(actions.downloadBulkReport.failure, (state, { payload: error }) => ({
    ...state,
    downloadBulkReportStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }));

export default reducer;
