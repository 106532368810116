import { createAction, createAsyncAction } from 'typesafe-actions';

const reset = createAction('PERMISSIONS/RESET')<undefined>();

const getPermissionsList = createAsyncAction(
  'PERMISSIONS/GET/REQUEST',
  'PERMISSIONS/GET/SUCCESS',
  'PERMISSIONS/GET/FAILURE',
)<undefined, any, any>();

export default {
  reset,
  getPermissionsList
} as const;
