import { createAsyncAction, createAction } from 'typesafe-actions';

const reset = createAction('USERS/RESET')<undefined>();

const getUsersList = createAsyncAction(
  'USERS/GET/REQUEST',
  'USERS/GET/SUCCESS',
  'USERS/GET/FAILURE',
)<any, any, any>();

const getUserNames = createAsyncAction(
  'USERS/GET_NAMES/REQUEST',
  'USERS/GET_NAMES/SUCCESS',
  'USERS/GET_NAMES/FAILURE',
)<any, any, any>();

const createUser = createAsyncAction(
  'USERS/CREATE/REQUEST',
  'USERS/CREATE/SUCCESS',
  'USERS/CREATE/FAILURE',
)<any, any, any>();

const updateUser = createAsyncAction(
  'USERS/UPDATE/REQUEST',
  'USERS/UPDATE/SUCCESS',
  'USERS/UPDATE/FAILURE',
)<any, any, any>();

const activateUser = createAsyncAction(
  'USERS/ACTIVATE/REQUEST',
  'USERS/ACTIVATE/SUCCESS',
  'USERS/ACTIVATE/FAILURE',
)<any, undefined, any>();

const inactivateUser = createAsyncAction(
  'USERS/INACTIVATE/REQUEST',
  'USERS/INACTIVATE/SUCCESS',
  'USERS/INACTIVATE/FAILURE',
)<any, undefined, any>();

const resetUsersList = createAction(
  'USERS/RESET_USERS_LIST'
)<undefined>();

const resetOperationStatus = createAction('USERS/RESET_OPERATION_STATUS')<any>();

export default {
  reset,
  getUsersList,
  getUserNames,
  createUser,
  updateUser,
  activateUser,
  inactivateUser,
  resetUsersList,
  resetOperationStatus,
} as const;
