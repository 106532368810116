import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API_MAP } from 'http/apiMap';
import requestGenerator from 'http/utils/requestGenerator';
import permissionsActions from './actions';

function* getPermissionsList(): SagaIterator {
  try {
    const { data, isAxiosError, response } = yield call(requestGenerator(API_MAP.getPermissionsList));

    if (isAxiosError) throw Error(response?.data?.error);

    const { results } = data;

    yield put(permissionsActions.getPermissionsList.success({ permissions: results }));
  } catch (error: any) {
    yield put(permissionsActions.getPermissionsList.failure({ message: error.message, data: error.data }));
  }
}

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(permissionsActions.getPermissionsList.request, getPermissionsList),
  ]);
}
