import { OperationStatus, AccountSettingsReducer } from '../types.d';
import { createReducer, ActionType } from 'typesafe-actions';
import actions from './actions';

type AccountSettingsActions = ActionType<typeof actions>;

const initialState: AccountSettingsReducer = {
  accountSettings: null,
  getAccountSettingsStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  },
  updateAccountSettingsStatus: {
    status: OperationStatus.INIT,
    errorMessage: '',
    errorData: {}
  }
};

const reducer = createReducer<AccountSettingsReducer, AccountSettingsActions>(initialState)
  .handleAction(actions.reset, (state) => ({
    ...state,
    ...initialState
  }))
  .handleAction(actions.resetOperationStatus, (state, { payload: actionName }) => ({
    ...state,
    [actionName]: OperationStatus.INIT,
  }))
  .handleAction(actions.setAccountSettings, (state, { payload: accountSettings }) => ({
    ...state,
    accountSettings,
  }))
  .handleAction(actions.getAccountSettings.request, (state) => ({
    ...state,
    getAccountSettingsStatus: {
      ...state.getAccountSettingsStatus,
      status: OperationStatus.PROCESS,
    },
  }))
  .handleAction(actions.getAccountSettings.success, (state, { payload: accountSettings }) => ({
    ...state,
    accountSettings,
    getAccountSettingsStatus: {
      ...state.getAccountSettingsStatus,
      status: OperationStatus.SUCCESS
    }
  }))
  .handleAction(actions.getAccountSettings.failure, (state, { payload: error }) => ({
    ...state,
    getAccountSettingsStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    }
  }))
  .handleAction(actions.updateAccountSettings.request, (state) => ({
    ...state,
    updateAccountSettingsStatus: {
      ...state.updateAccountSettingsStatus,
      status: OperationStatus.PROCESS
    },
  }))
  .handleAction(actions.updateAccountSettings.success, (state, { payload: accountSettings }) => ({
    ...state,
    accountSettings,
    updateAccountSettingsStatus: {
      ...state.updateAccountSettingsStatus,
      status: OperationStatus.SUCCESS
    },
  }))
  .handleAction(actions.updateAccountSettings.failure, (state, { payload: error }) => ({
    ...state,
    updateAccountSettingsStatus: {
      status: OperationStatus.FAILURE,
      errorMessage: error.message,
      errorData: error.data
    },
  }))

export default reducer;
